import _ from 'lodash'
import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
	id: number,
	clientId: string,
	locationId: string,
	deliveryStage: string,
	orderSpreadsheetFormatId: string,
	status: number,
	source_key: string,
	result_key: string,
	createdAt: Date,
	updatedAt: Date,
}} OrdersInfoEdit */

/**
	@type {(params: {
		clientId: string,
		orderSpreadsheetFormatId: string,
		deliveryStage: 'LAST_MILE' | 'FIRST_MILE',
		locationId: string
	}, data: FormData) => Promise<string>} 
 */
export const postEditOrdersInfo = async (params, data) => {
	const response = await axiosApiClient({
		method: 'POST',
		url: '/backoffice/v1/orders/info-edit',
		params,
		data
	})
	return response.data
}

/**
	@type {(id: number) => Promise<OrdersInfoEdit>} 
 */
export const getEditOrdersInfo = async id => {
	const response = await axiosApiClient({
		method: 'GET',
		url: `/backoffice/v1/orders/info-edit/${id}`
	})
	return response.data
}
