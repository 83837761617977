import _ from 'lodash'
import { postOpsUseReport } from '@/api/driver-payment-report-api'
import { featureFlags } from '@/config'
import { getRealTimeCourierReportByCourierId } from './ajax'
import * as Sentry from '@sentry/vue'

export const actions = {
	async exportOpsUseReportByCourierId({ commit }, filter) {
		const { locationTimezone, locationId, courierId, startDate, endDate } =
			filter

		if (!featureFlags.LLP_24216_DRIVER_PAYMENT_REPORT_PHASE_2) {
			await getRealTimeCourierReportByCourierId(
				locationTimezone,
				locationId,
				courierId,
				startDate,
				endDate
			)
		} else {
			try {
				const response = await postOpsUseReport(
					Number(courierId),
					locationId,
					startDate,
					endDate
				)
				const statusCode = _.get(response, 'status')

				if (statusCode === 204) {
					alert('No report found')
				} else if (statusCode === 200) {
					const data = _.get(response, 'data')
					if (data) {
						const blob = new Blob([data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						})

						//	Get file name
						const contentDisposition = _.get(
							response,
							'headers.content-disposition'
						)
						const fileName = contentDisposition
							? contentDisposition.split('=')[1]
							: 'exportFile.xlsx'

						//	Download with file name
						const link = document.createElement('a')
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
						URL.revokeObjectURL(link.href)
					}
				}
			} catch (e) {
				// Try to extract error message
				let errorMessage = null
				try {
					errorMessage = _.get(
						JSON.parse(await e.response.data.text()),
						'errors.0.detail'
					)
				} catch (extractError) {
					// just do nothing
				}

				Sentry.captureMessage(
					'ExportOpsUseReportByCourierId:Unknown Error',
					{
						level: 'error',
						extra: {
							error: {
								filter,
								stack: e.stack,
								errorMessage
							}
						}
					}
				)

				window.alert(errorMessage || e)
			}
		}
	}
}
