import _ from 'lodash'
import moment from 'moment-timezone'
import logger from '@/lib/utils/log'
import { getOrder } from '@/api/orders-api'
import { getClientName } from '@/api/clients-api'
import { getLocationTimezone } from '@/api/locations-api'
import { getOrderEvents } from '@/api/order-events-api'
import { utcFormat } from '@/lib/plugins/date'
import { getDeliveries } from '@/api/deliveries-api'
import { getFormReasons } from '@/api/form-fields-api'

const ordersMutation = {
	setOrderInfo: 'SET_ORDER_INFO',
	setOrderClientName: 'SET_ORDER_CLIENT_NAME',
	setOrderTimezone: 'SET_ORDER_TIMEZONE',
	setOrderEvents: 'SET_ORDER_EVENTS',
	setDeliveries: 'SET_DELIVERIES',
	setReasons: 'SET_REASONS'
}

const state = {
	orderInfo: {},
	clientName: null,
	timezone: 'Asia/Hong_Kong',
	orderEvents: [],
	deliveries: [],
	reasons: {}
}

const mutations = {
	[ordersMutation.setOrderInfo]: function (state, orderInfo) {
		_.set(state, 'orderInfo', orderInfo)
	},
	[ordersMutation.setOrderClientName]: function (state, clientName) {
		_.set(state, 'clientName', clientName)
	},
	[ordersMutation.setOrderTimezone]: function (state, timezone) {
		_.set(state, 'timezone', timezone)
	},
	[ordersMutation.setOrderEvents]: function (state, orderEvents) {
		_.set(state, 'orderEvents', orderEvents)
	},
	[ordersMutation.setDeliveries]: function (state, deliveries) {
		_.set(state, 'deliveries', deliveries)
	},
	[ordersMutation.setReasons]: function (state, reasons) {
		_.set(state, 'reasons', reasons)
	}
}

const actions = {
	async getOrderById({ commit }, orderId) {
		try {
			const [orderDetail, orderEvents] = await Promise.all([
				getOrder(orderId),
				getOrderEvents(orderId)
			])
			const [clientName, timezone] = await Promise.all([
				getClientName(orderDetail.clientId),
				getLocationTimezone(orderDetail.locationId)
			])
			const deliveries = _.filter(
				await getDeliveries({
					clientRef: _.get(orderDetail, 'clientRefs.0.value', ''),
					limit: 25
				}),
				{ orderId }
			)

			commit(ordersMutation.setOrderInfo, orderDetail)
			commit(ordersMutation.setOrderClientName, clientName)
			commit(ordersMutation.setOrderTimezone, timezone)
			commit(ordersMutation.setOrderEvents, orderEvents)
			commit(ordersMutation.setDeliveries, deliveries)
		} catch (e) {
			logger.error('Error on order - getOrderById', e.stack)
		}
	},
	async getReasons({ commit }) {
		try {
			const reasons = _.reduce(
				await getFormReasons(),
				(acc, formField) => {
					const clientId = formField.clientId || 'COMMON'
					_.forEach(formField.options, ({ tag, translations }) =>
						_.set(acc, `${clientId}.${tag}`, translations[0].value)
					)
					return acc
				},
				{}
			)

			commit(ordersMutation.setReasons, reasons)
		} catch (e) {
			logger.error('Error on order - getFormReasons', e.stack)
		}
	}
}

const getters = {
	orderRef: state => _.get(state, 'orderInfo.clientRefs.0.value', ''),
	formatDate: state => date => utcFormat(date, state.timezone),
	lastUpdatedAt: state => {
		const dates = [
			state.orderInfo.updatedAt,
			..._.map(state.orderEvents, 'createdAt'),
			..._.map(state.deliveries, 'updatedAt')
		]
		const latest = moment.max(dates.map(d => moment(d)))
		return utcFormat(latest, state.timezone)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
