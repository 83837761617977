import _ from 'lodash'

const createValidator = rules => label => _.map(rules, rule => rule(label))

const rules = {
	number: label => val => {
		const numVal = Number(val)
		if (!_.isNumber(numVal) || Number.isNaN(numVal)) {
			return `${label} should be number`
		}
		return null
	},
	nonNull: label => val => {
		if (_.isEmpty(val)) {
			return `${label} cannot be empty`
		}
		return null
	},
	nonEmptyNumber: label => val => {
		if (!val || !val.toString().match(/^[+-]?([0-9]*[.])?[0-9]+$/g)) {
			return `${label} should be non-empty number`
		}
		return null
	},
	limitedChars: (label, limitCount) => val => {
		if (!val || val.length < limitCount) {
			return `${label} should be more than ${limitCount} characters`
		}
		return null
	},
	numericString: label => val => {
		if (!val.toString().match(/^[0-9]+$/)) {
			return `${label} should be number`
		}
		return null
	},
	lowCode: () => val => {
		if (!val.toString().match(/^[A-Z]{2}(_[A-Z0-9]{3})$/g)) {
			return `Please type something like 'HK_HKG', 'PH_MNL', etc..`
		}
		return null
	},
	lat: label => val => {
		if (val > 90 || val < -90) {
			return `${label} should be a number between -90 to 90`
		}
		return null
	},
	lng: label => val => {
		if (val > 180 || val < -180) {
			return `${label} should be a number between -180 to 180`
		}
		return null
	},
	time24Format: label => val => {
		if (
			!val ||
			!val.toString().match(/^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])?$/g)
		) {
			return `${label} should be time with 24hrs format (e.g. 07:00)`
		}
		return null
	},
	positiveNumber: label => val => {
		if (!val || val <= 0) {
			return `${label} should be positive number`
		}
		return null
	}
}

const id = createValidator([rules.nonNull, rules.number])

const nullableID = createValidator([rules.number])

const required = createValidator([rules.nonNull])

const nonEmptyNumber = createValidator([rules.nonEmptyNumber])

const numericString = createValidator([rules.numericString])

const lowcode = createValidator([rules.lowCode])

const lat = createValidator([rules.lat, rules.nonEmptyNumber])

const lng = createValidator([rules.lng, rules.nonEmptyNumber])

const time24Format = createValidator([rules.time24Format])

const clientId = createValidator([label => rules.limitedChars(label, 3)])

const time24WithSecondsFormat = createValidator([
	label => val =>
		val?.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g) ||
		`${label} should be a valid time (e.g. 10:00:00)`
])

const nullableCommaSplitNumbers = createValidator([
	() => val =>
		val.match(/^$|^\d+(,\d+)*$/) ||
		`Please input number only and separate each courier ID with a ","`
])

const positiveNumber = createValidator([rules.positiveNumber])

const validator = {
	id,
	required,
	nullableID,
	nonEmptyNumber,
	numericString,
	lowcode,
	lat,
	lng,
	time24Format,
	clientId,
	time24WithSecondsFormat,
	positiveNumber,
	nullableCommaSplitNumbers
}

export const ValidatorPlugin = {
	install(Vue) {
		Vue.prototype.$validator = validator
	}
}
