import _ from 'lodash'
import axiosApiClient from './axiosApiClient'

// TODO: update the client endpoint with now endpoint format: e.g. '/v1/clients?limit=100'
/**
 * @typedef {{
	id: string,
	locations: Record<string, any>[],
	translations: Record<string, any>[],
	attributes: Record<string, any>,
	baseClientId?: string,
	canCancelOrders?: boolean,
	domains?: Record<string, any>[],
	orderTags?: Record<string, any>[],
	createdAt?: string,
	updatedAt?: string
}} Client
 */

/** @type {(options: {limit?: number }) => Promise<Client[]>} */
export const getClients = async ({ limit } = {}) => {
	/** @type {import('axios').AxiosResponse<Client[]>} */
	const response = await axiosApiClient({
		url: '/clients'
	})
	return response.data
}

/** @type {(id) => Promise<Client[]>} */
export const getClient = async clientId => {
	/** @type {import('axios').AxiosResponse<Client[]>} */
	const response = await axiosApiClient.get(`/clients/${clientId}`)
	return response.data
}

/** @type {(id) => Promise<string>} */
export const getClientName = async clientId => {
	const client = await getClient(clientId)
	return _.get(client, 'translations.0.name')
}
