import _ from 'lodash'

import axiosApiClient from '../../../api/axiosApiClient'
import logger from '../../../lib/utils/log'

export async function patchOrderForCourier(orderId, userIds) {
	try {
		await axiosApiClient({
			method: 'PATCH',
			url: `/orders/${orderId}`,
			data: { userIds }
		})
	} catch (e) {
		logger.error('Error on PATCH /orders', null, e.stack)
	}
}

export async function getLocaleIds() {
	try {
		const { data: locales = [] } = await axiosApiClient.get('locales', {
			params: { limit: 200 }
		})
		return locales.map(locale => locale.id)
	} catch (e) {
		logger.error('Error on GET /locales', null, e.stack)
		return []
	}
}

export async function getClientList() {
	try {
		const { data: clientList = [] } = await axiosApiClient.get('clients', {
			params: { limit: 200 }
		})
		return clientList.map(client => client.id)
	} catch (e) {
		logger.error('Error on GET /clients', null, e.stack)
		return []
	}
}

// export async function triggerMatchmaking(orderId) {
// 	try {
// 		await axiosApiClient({
// 			method: 'GET',
// 			url: `/orders/${orderId}/matchmaking`
// 		})
// 	} catch (e) {
// 		logger.error(
// 			'Error triggering matchmaking for this order',
// 			null,
// 			e.stack
// 		)
// 	}
// }

export async function exportOrdersInCSV(filter) {
	const { locationId } = filter
	try {
		const response = await axiosApiClient({
			method: 'GET',
			url: '/v1/dashboard/export',
			responseType: 'blob',
			params: { ...filter }
		})
		let filename = `${locationId}.zip`
		const content = response.headers['content-disposition']
		const matches = content.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '')
		}
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob([response.data]))
		link.download = filename
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	} catch (e) {
		logger.error('order export', { category: 'API' }, e.stack)
		alert(e)
	}
}

export async function getFirstClientId() {
	try {
		const { data } = await axiosApiClient.get('clients')
		return data.map(_ => _.id)[0]
	} catch (e) {
		logger.error('clients', { category: 'API' }, e.stack)
	}
}

export async function getDashboardList(params = {}) {
	try {
		return _.get(
			await axiosApiClient.get('/v1/dashboard', {
				params
			}),
			'data',
			[]
		)
	} catch (e) {
		logger.error(
			'[Backoffice][Dashboard] Failed to get dashboard list',
			{ requestQuery: params },
			e.stack
		)
		return {}
	}
}

export async function getDashboardListByOrderIds(orderIds) {
	try {
		return _.get(
			await axiosApiClient.get(`/v1/dashboard/orderIds/${orderIds}`),
			'data',
			[]
		)
	} catch (e) {
		logger.error(
			'[Backoffice][Dashboard] Failed to get dashboard list by order Ids',
			{ orderIds },
			e.stack
		)
		return []
	}
}

export async function getUserAliasTypes() {
	try {
		const resp = await axiosApiClient.get(`/users/schemas/post`)
		return _.get(
			resp,
			'data.body.items.properties.aliases.items.properties.type.enum',
			[]
		)
	} catch (err) {
		logger.error(
			'[Backoffice][getUserAlias] Failed to get user alias list',
			{
				error: err
			},
			err.stack
		)
	}
}
