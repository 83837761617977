import axiosApiClient from './axiosApiClient'
import logger from '@/lib/utils/log'

/**
 * @typedef {{
	userIds: string,
    startDate: string,
    endDate: string,
    clientId: string,
    locationId: string,
    locale: string
}} requestBody
 */

/**
 * @typedef {{
    id: string,
    status: 0 | 1 | 2,
    downloadUrl: string | null
}} DriverPaymentReport
 */

/** @type {(body: requestBody) => Promise<DriverPaymentReport>} */

export async function postDriverUseReport(body) {
	try {
		const { data } = await axiosApiClient.post(
			`/backoffice/v1/driver-payment-driver-reports`,
			body
		)
		return data
	} catch (e) {
		logger.warning(
			`Error on POST /backoffice/v1/driver-payment-driver-reports body:${JSON.stringify(
				body
			)}`,
			null,
			e.stack
		)
		throw e
	}
}

/** @type {(reportId: string) => Promise<DriverPaymentReport>} */

export async function getDriverUseReport(reportId) {
	try {
		const { data } = await axiosApiClient.get(
			`/backoffice/v1/driver-payment-driver-reports/${reportId}`
		)
		return data
	} catch (e) {
		logger.warning(
			`Error on GET /backoffice/v1/driver-payment-driver-reports/${reportId}`,
			null,
			e.stack
		)
		throw e
	}
}

/** @type {(userId: number, locationId: string, startTime: string, endTime: string) => Promise<{data: Blob}>} */

export async function postOpsUseReport(userId, locationId, startTime, endTime) {
	const body = {
		locationId,
		userId,
		startTime,
		endTime
	}

	try {
		const response = await axiosApiClient.post(
			`/backoffice/v1/driver-payment-ops-reports`,
			body,
			{
				responseType: 'blob'
			}
		)

		return response
	} catch (e) {
		logger.warning(
			`Error on POST /backoffice/v1/driver-payment-ops-reports, body: ${JSON.stringify(
				body
			)}`,
			null,
			e.stack
		)
		throw e
	}
}
