import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
 	id: string,
	timezone": string,
	latitude": string,
	longitude": string,
	country_id": string,
	city_code_map": string
}} City
 */
/**
 * @typedef {{
	city_id: number,
	driver_id: number,
	full_name: string
	name_xing: string,
	name_ming: string,
	phone_no: string,
	vehicle_id: number,
	account_status: number,
}} Driver
 */

/** @type {() => Promise<Array>} */
export const getCountries = async () => {
	/** @type {import('axios').AxiosResponse<Array>} */
	const response = await axiosApiClient.get(`/v1/infos/countries`)
	return response.data
}

/** @type {(query: {cityId?: number, countryId?: number}) => Promise<City[]>} */
export const getCities = async ({ cityId, countryId } = {}) => {
	const cityIdQuery = cityId ? `cityId=${cityId}` : ''
	const countryIdQuery = countryId ? `countryId=${countryId}` : ''

	/** @type {import('axios').AxiosResponse<City[]>} */
	const response = await axiosApiClient.get(
		`/v1/infos/cities${
			cityIdQuery
				? `?${cityIdQuery}`
				: countryIdQuery
				? `?${countryIdQuery}`
				: ''
		}`
	)
	return response.data
}

/** @type {(query: {countryId: number, driverId?: string, phoneNumber?: string}) => Promise<Driver[]>} */
export const getDrivers = async ({ countryId, driverId, phoneNumber }) => {
	const driverIdQuery = driverId ? `driverId=${driverId}` : ''
	const phoneNumberQuery = phoneNumber ? `phoneNumber=${phoneNumber}` : ''

	/** @type {import('axios').AxiosResponse<Driver[]>} */
	const response = await axiosApiClient.get(
		`/v1/infos/drivers?countryId=${countryId}&${
			driverIdQuery ? driverIdQuery : phoneNumberQuery
		}`
	)
	return response.data
}
