import _ from 'lodash'
import brPrdConfig from './br.prd.config'
import stgConfig from './stg.config'
import preConfig from './pre.config'
import prdConfig from './prd.config'
import ldevConfig from './ldev.config'

export const APP_ENV = process.env.VUE_APP_ENV
export const HLL_REGION = process.env.VUE_APP_HLL_REGION

const configs = {
	sg: {
		stg: stgConfig,
		pre: preConfig,
		prd: prdConfig
	},
	br: {
		prd: brPrdConfig
	}
}

/**
 * @type {{
 *     featureFlags: Record<string, boolean>
 * }}
 */
export const envConfig = _.has(configs, `${HLL_REGION}.${APP_ENV}`)
	? configs[HLL_REGION][APP_ENV]
	: ldevConfig
