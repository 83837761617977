import _ from 'lodash'
import axiosApiClient from './axiosApiClient'
import logger from '@/lib/utils/log'

/**
 * @typedef {{
	id: string
	userId?: number,
	orderId?: number,
	deliveryId?: number,
	orderRef?: string,
	parcelRef?: string,
	action: string,
	status: string,
	createdAt: Date,
	updatedAt: Date
}} AuditLog
   @typedef {{
	page: number,
	limit: number,
	sortBy?: string,
	sortDirection?: string,
	ids?: number[],
	userId?: number,
	orderId?: number,
	deliveryId?: number,
	orderRef?: string,
	parcelRef?: string,
	action?: string,
	status?: string,
	createdAt?: { from: Date, to: Date }
}} AuditLogQueryParams
 */

/**
 * @type {(params: {grouping: boolean}) => Promise<{ [key: string]: string[] } | string[]>}
 */
export async function getAuditLogActions(params = { grouping: false }) {
	try {
		return _.get(
			await axiosApiClient('/v1/audit-logs/actions', { params }),
			'data',
			{}
		)
	} catch (e) {
		logger.error(
			'[Backoffice][AuditLogs] Failed to get audit log actionlist',
			e,
			e.stack
		)
		return {}
	}
}

/**
 * @type {
 * 	(params: AuditLogQueryParams) =>
 * 	Promise<{total: number, page: number, limit: number, data: AuditLog[]}>
 * 	}
 */
export async function getAuditLogs(params = {}) {
	try {
		return _.get(
			await axiosApiClient('/audit-logs', { params }),
			'data',
			{}
		)
	} catch (e) {
		logger.error(
			'[Backoffice][AuditLogs] Failed to get audit log list',
			e,
			e.stack
		)
		return {}
	}
}
