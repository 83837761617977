import { getLocations } from '@/api/locations-api'
import { mutationType, defaultShiftDashboardPageLimit } from './config'
import {
	approveShiftRegistrations,
	getShiftRegistrations
} from '@/api/shifts-api'
import { startOfDay } from '@/lib/plugins/date'
import _ from 'lodash'
import moment from 'moment-timezone'
import logger from '@/lib/utils/log'

const state = {
	data: [],
	totalPage: 1,
	total: 0,
	statusCount: {
		APPROVED: 0,
		PENDING: 0,
		REJECTED: 0
	},
	selectedShiftRegistrations: [],
	locationList: [],
	filter: {
		...initialFilter
	}
}

export const initialFilter = {
	date: startOfDay('YYYY-MM-DD'),
	locationId: 'HK_HKG',
	shifts: null,
	status: null,
	limit: defaultShiftDashboardPageLimit,
	page: 1
}

const mutations = {
	[mutationType.setShiftDashboard](state, { data, total, statusCount }) {
		const totalPage =
			total <= state.filter.limit ? 1 : _.ceil(total / state.filter.limit)
		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
		_.set(state, 'statusCount', statusCount)
	},
	[mutationType.setLocationList](state, locationList) {
		_.set(state, 'locationList', locationList)
	},
	[mutationType.setFilter](state, filter) {
		_.set(state, 'filter', { ...state.filter, ...filter })
	},
	[mutationType.setSelectedShiftRegistrations](
		state,
		selectedShiftRegistrations
	) {
		_.set(state, 'selectedShiftRegistrations', selectedShiftRegistrations)
	}
}

const actions = {
	async getLocationList({ commit }) {
		const locationList = _.get(await getLocations(), 'data', [])
		commit(mutationType.setLocationList, locationList)
	},
	async getShiftDashboard({ commit, dispatch }, filter = {}) {
		const params = {
			...state.filter,
			...filter
		}
		const { startAt = '00:00:00', endAt = '23:59:59' } = JSON.parse(
			params.shifts || '{}'
		)
		const timezone = _.find(state.locationList, {
			id: params.locationId
		})?.timezone
		const shiftStartAt = moment
			.tz(`${params.date} ${startAt}`, timezone)
			.utc()
			.format()
		const shiftEndAt = moment
			.tz(`${params.date} ${endAt}`, timezone)
			.utc()
			.format()
		const mappedParams = {
			...params,
			shiftStartAt,
			shiftEndAt,
			shifts: null,
			date: null
		}
		try {
			const { data, total, page, limit, statusCount } =
				await getShiftRegistrations(mappedParams)
			dispatch('updateFilter', { ...params, page, limit })
			commit(mutationType.setShiftDashboard, { data, total, statusCount })
		} catch (e) {
			logger.error(
				'Error on GET shiftRegistrations List',
				e.response?.data?.errors || e.stack
			)
		}
	},
	updateFilter({ commit }, filter = {}) {
		commit(mutationType.setFilter, filter)
	},
	resetFilter({ commit }) {
		commit(mutationType.setFilter, initialFilter)
	},
	updateSelectedShiftRegistrations({ commit }, selectedShiftRegistrations) {
		commit(
			mutationType.setSelectedShiftRegistrations,
			selectedShiftRegistrations
		)
	},
	async approveShiftRegistrations(
		{ dispatch },
		{ shiftRegistrations, status }
	) {
		try {
			const shiftRegistrationId = shiftRegistrations.map(({ id }) => id)
			await approveShiftRegistrations({ shiftRegistrationId, status })
			dispatch('getShiftDashboard', this.filter)
		} catch (e) {
			logger.error(
				'Error on POST approve-shift-registrations',
				e.response?.data?.errors || e.stack
			)
		}
	}
}

const getters = {
	locationList: state => state.locationList,
	liftedFilter: state => state.filter,
	locationIdList: state => state.locationList.map(({ id }) => id)
}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced
}
