import _ from 'lodash'
import logger from '@/lib/utils/log'
import {
	getUsers as getCouriers,
	getUser as getCourierById,
	createUsers as createCouriers,
	updateUser as updateCourier
} from '@/api/users-api'
import { getLocales } from '@/api/locales-api'
import { getDrivers, getCities } from '@/api/infos-api'
import { getRegions } from '@/api/regions-api'

const couriersMutation = {
	setCourierList: 'SET_COURIER_LIST',
	setFilter: 'SET_FILTER',
	setCourierInfo: 'SET_COURIER_INFO',
	setCountryAreaCodeOptions: 'SET_COUNTRY_AREA_CODE_OPTIONS',
	setLocaleOptions: 'SET_LOCALE_OPTIONS',
	setCityList: 'SET_CITY_LIST',
	setDriverList: 'SET_DRIVER_LIST'
}

const defaultFilter = {
	page: 1,
	limit: 100,
	sortBy: 'id',
	sortDirection: 'DESC',
	id: null,
	driverId: null,
	name: null,
	phone: null,
	locationId: null,
	clientId: null
}

const state = {
	data: [],
	total: 0,
	totalPage: 1,
	filter: defaultFilter,
	courierInfo: {},
	formData: {
		countryAreaCodeOptions: [],
		localeOptions: [],
		cityList: [],
		driverList: []
	}
}

const mutations = {
	[couriersMutation.setCourierList]: function (
		state,
		{ data = [], total = 0, totalPage = 1 }
	) {
		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
	},
	[couriersMutation.setFilter]: function (state, filter = {}) {
		_.set(state, 'filter', { ...state.filter, ...filter })
	},
	[couriersMutation.setCourierInfo]: function (state, courierInfo) {
		_.set(state, 'courierInfo', courierInfo)
	},
	[couriersMutation.setCountryAreaCodeOptions]: function (
		state,
		countryAreaCodeOptions
	) {
		_.set(state, 'formData.countryAreaCodeOptions', countryAreaCodeOptions)
	},
	[couriersMutation.setLocaleOptions]: function (state, localeOptions) {
		_.set(state, 'formData.localeOptions', localeOptions)
	},
	[couriersMutation.setCityList]: function (state, cityList) {
		_.set(state, 'formData.cityList', cityList)
	},
	[couriersMutation.setDriverList]: function (state, driverList) {
		_.set(state, 'formData.driverList', driverList)
	}
}

const actions = {
	async getCouriers({ state, commit, dispatch }, filter = {}) {
		//	Build params with the state.filter(old state) and filter(new input)
		const filteringPagination = filter.page
			? filter
			: { ...defaultFilter, ...filter }
		const params = { ...state.filter, ...filteringPagination }

		const { data, total, page, limit } = await getCouriers(
			courierFilterMapping(params)
		)
		const modifiedData = usersDataModifier(data)
		const totalPage = total <= limit ? 1 : _.ceil(total / limit)

		await commit(couriersMutation.setCourierList, {
			data: modifiedData,
			total,
			totalPage
		})
		await dispatch('updateFilter', { ...params, page, limit })
	},
	async getCourierById({ commit }, courierId) {
		try {
			const courierDetail = await getCourierById(courierId)

			const phoneAlias = _.find(courierDetail.aliases, { type: 'PHONE' })
			const telegramAlias = _.find(courierDetail.aliases, {
				type: 'TELEGRAM'
			})

			commit(couriersMutation.setCourierInfo, {
				id: _.get(courierDetail, 'id'),
				driverId: _.get(courierDetail, 'driverId'),
				name: _.get(courierDetail, 'info.name', ''),
				locationCode: _.get(courierDetail, 'locationId', ''),
				locale: _.get(courierDetail, 'info.locale', ''),
				phone: _.get(phoneAlias, 'id', ''),
				telegram: _.get(telegramAlias, 'id', ''),
				isVerified: _.get(courierDetail, 'isVerified', false),
				clients: _.map(_.get(courierDetail, 'clients', []), client => ({
					clientId: client
				}))
			})
		} catch (e) {
			logger.error('Error on courier - getCourierById', e.stack)
		}
	},
	updateFilter({ commit }, filter) {
		commit(couriersMutation.setFilter, filter)
	},
	resetFilter({ commit }) {
		commit(couriersMutation.setFilter, defaultFilter)
	},
	async getCountryAreaCodeOptions({ commit }) {
		try {
			const regions = await getRegions()

			//	store the country id and area for driver search
			const areaCodeOptions = _.map(regions, region => {
				const defaultLanguage = _.get(region, 'defaultLanguage')
				const defaultTranslation = _.get(
					_.find(region.translations, { id: defaultLanguage }),
					'value',
					'N/A'
				)
				const label =
					`${defaultTranslation}` + `(${_.get(region, 'areaCode')})`
				return {
					label,
					value: region.areaCode,
					countryId: region.countryId
				}
			})

			commit(couriersMutation.setCountryAreaCodeOptions, areaCodeOptions)
		} catch (e) {
			logger.error(
				'Error on courier - getCountryAreaCodeOptions',
				e.stack
			)
		}
	},
	async getLocaleOptions({ commit }) {
		try {
			const localesDetail = await getLocales({})
			commit(
				couriersMutation.setLocaleOptions,
				_.map(localesDetail, locale => ({
					label: locale.id,
					value: locale.id
				}))
			)
		} catch (e) {
			logger.error('Error on courier - getLocaleOptions', e.stack)
		}
	},
	async getCities({ commit }) {
		try {
			const cityList = await getCities()
			commit(couriersMutation.setCityList, cityList)
		} catch (e) {
			logger.error('Error on courier - getCities', e.stack)
		}
	},
	async syncCourier({ state, commit, dispatch, getters }) {
		try {
			await dispatch('getCities')
			const city = getters.courierCity

			await dispatch('getDrivers', {
				driverId: state.courierInfo.driverId,
				countryId: city.country_id
			})
			const driver = _.head(_.get(state, 'formData.driverList')) || {}

			commit(couriersMutation.setCourierInfo, {
				...state.courierInfo,
				name: _.get(driver, 'driverName', ''),
				locationCode: _.get(driver, 'cityCode', ''),
				phone: _.get(driver, 'driverPhone', '')
			})
		} catch (e) {
			logger.error('Error on courier - syncCourier', e.stack)
		}
	},
	async getDrivers({ state, commit, dispatch }, driverInfo) {
		let result = []
		try {
			if (
				_.has(driverInfo, 'countryId') &&
				(_.has(driverInfo, 'phoneNumber') ||
					_.has(driverInfo, 'driverId'))
			) {
				const resp = await getDrivers(driverInfo)

				await dispatch('getCities')
				const cityList = _.get(state, 'formData.cityList', [])

				result = _.map(resp, driver => ({
					cityCode: _.get(
						_.find(cityList, { id: driver.city_id.toString() }),
						'city_code_map',
						''
					),
					driverId: driver.driver_id,
					driverName: driver.full_name,
					driverPhone: driver.phone_no,
					driverVehiclePlate: driver.vehicle_id,
					driverStatus: driver.account_status
				}))
			}
		} catch (e) {
			logger.error('Error on courier - getDrivers', e.stack)
		}
		if (_.isEmpty(result)) {
			alert('No driver found!')
		}
		commit(couriersMutation.setDriverList, result)
	},
	// AJAX Calling
	async createCouriers({ commit }, couriers) {
		try {
			const newCouriers = _.map(couriers, courier => ({
				...courier,
				roles: ['COR'],
				tags: []
			}))
			return await createCouriers(newCouriers)
		} catch (e) {
			logger.error('Error on courier - createCouriers', e.stack)
			return _.get(e, 'response.data', {})
		}
	},
	async updateCourier({ commit }, courier) {
		try {
			if (_.has(courier, 'id')) {
				return await updateCourier({
					...courier,
					roles: ['COR'],
					tags: []
				})
			}
			return false
		} catch (e) {
			logger.error('Error on courier - updateCourier', e.stack)
			return _.get(e, 'response.data', {})
		}
	},
	// Reset State
	resetDrivers({ commit }) {
		commit(couriersMutation.setDriverList, [])
	},
	resetState({ commit }) {
		commit(couriersMutation.setCourierInfo, {})
		commit(couriersMutation.setCityList, [])
		commit(couriersMutation.setCountryAreaCodeOptions, [])
		commit(couriersMutation.setDriverList, [])
		commit(couriersMutation.setLocaleOptions, [])
	}
}

const courierFilterMapping = ({
	page = 1,
	phone,
	name,
	clientId,
	...params
}) => {
	const conditions = { userRoleRole: 'COR', page }
	if (phone) {
		//	alias
		conditions['userAliasId'] = phone
		conditions['userAliasType'] = 'PHONE'
	}
	if (name) {
		//	info
		conditions['userInfoName'] = name
	}
	if (clientId) {
		//	client
		conditions['usersClientsClientId'] = clientId
	}
	//	sortBy, sortDirection, id, driverId, locationId
	_.forEach(params, (value, index) => {
		if (params[index]) {
			conditions[index] = value
		}
	})
	return conditions
}

const usersDataModifier = users => {
	return _.map(users, user => {
		//	Get preferred phone alias and telegram alias if exist and not empty
		const preferredAlias = _.find(_.get(user, 'aliases', []), 'preferred')
		const phoneAlias =
			preferredAlias?.type === 'PHONE'
				? preferredAlias
				: _.find(_.get(user, 'aliases', []), ['type', 'PHONE'])
		const telegramAlias =
			preferredAlias?.type === 'TELEGRAM'
				? preferredAlias
				: _.find(_.get(user, 'aliases', []), ['type', 'TELEGRAM'])

		return {
			...user,
			name: _.get(user, 'info.name', ''),
			phone: _.get(phoneAlias, 'value', ''),
			telegram: _.get(telegramAlias, 'value'),
			clients: _.map(user.clients, 'clientId').join(', ')
		}
	})
}

const getters = {
	courierCity: state => {
		const locationCode = _.get(state, 'courierInfo.locationCode')
		const cityList = _.get(state, 'formData.cityList')
		return _.find(cityList, { city_code_map: locationCode }) || {}
	}
}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced
}
