import VueRouter from 'vue-router'
import { routes } from '@/router/routes'
import log from '@/lib/utils/log'

// Fix NavigationDuplicated error: https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(log.warn)
}
// Create the router instance and pass the `routes` option
const router = new VueRouter({
	routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
	if (!localStorage.getItem('token') && to.name !== 'Login') {
		next('/login')
	} else {
		next()
	}
})

export default router
