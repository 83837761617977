import _ from 'lodash'
import moment from 'moment-timezone'

import {
	getListCourierReport,
	getSingleCourierReportByCourierId,
	getBulkCourierReports,
	getLatestCourierReports
} from './ajax'

export const actions = {
	async getListCourierReport({ commit }, filter) {
		const reportsList = await getListCourierReport(filter)
		return reportsList
	},
	async downloadSingleGeneratedReport({ commit }, filter) {
		const { courierId, selectedPeriod, location } = filter
		const tempDate = moment(selectedPeriod).toDate()
		const response = await getSingleCourierReportByCourierId(
			courierId,
			tempDate,
			location
		)
		//	open a window for download
		if (!_.isEmpty(_.get(response, 'data'))) {
			window.open(response.data)
		} else {
			alert('No report found')
		}
	},
	async downloadBulkGeneratedReports({ commit }, filter) {
		const { selectedPeriod, location } = filter

		const tempDate = moment(selectedPeriod).toDate()
		const response = await getBulkCourierReports(tempDate, location)
		//	open a window for download
		if (!_.isEmpty(_.get(response, 'data'))) {
			window.open(response.data)
		} else {
			alert('No report found')
		}
	},
	async downloadLatestGeneratedReports({ commit }, filter) {
		const { selectedPeriod, location } = filter
		const tempDate = moment(selectedPeriod).toDate()
		return await getLatestCourierReports(tempDate, location)
	}
}
