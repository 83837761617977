/* global $ */
import moment from 'moment-timezone'

export const defaultDateFormat = 'YYYY-MM-DD HH:mm'
export const utcDateFormat = 'YYYY-MM-DD HH:mm (UTCZ)'

export const format = (date, timezone, dateFormat = defaultDateFormat) => {
	if (timezone) {
		const timezoneFormat = dateFormat + ' (z)'
		return moment(date).tz(timezone).format(timezoneFormat)
	}
	return moment(date).format(dateFormat)
}

export const utcFormat = (date, timezone) =>
	moment(date).tz(timezone).format(utcDateFormat)

export const unaryFormat = date => moment(date).format(defaultDateFormat)

export const today = () => moment().format(defaultDateFormat)

export const startOfDay = (dateFormat = defaultDateFormat) =>
	moment().startOf('day').format(dateFormat)

export const endOfDay = (dateFormat = defaultDateFormat) =>
	moment().endOf('day').format(dateFormat)

export const toDate = date => {
	const result = moment(date)
	return result.isValid() ? result.toDate() : null
}

/**
 * Helper to fix wrong date output in jsonForm
 *
 * @type {(value: string, tz: string) => string}
 */
export const fixDateOffset = (value, tz) => {
	const offset = moment(value).utcOffset() - moment(value).tz(tz).utcOffset()
	return moment(value).add(offset, 'minutes').toISOString()
}

/**
 * Change timezone of a bootstrap-datetimepicker input
 *
 * @type {(name: string, tz: string) => void}
 */
export const changeDateInputTz = (name, tz) => {
	if (typeof name !== 'string' || typeof tz !== 'string') {
		return
	}
	const labelEl = document.querySelector(`.jsonform-error-${name} label`)
	const input = $(`input[name="${name}"]`)
	const picker = input.data('DateTimePicker')
	if (!labelEl || !picker) {
		return
	}

	// set original label (generated by jsonfrom) to data-label
	if (!labelEl.getAttribute('data-label')) {
		labelEl.setAttribute('data-label', labelEl.innerText.replace(':', ''))
	}

	// show label with timezone
	labelEl.innerText = `${labelEl.getAttribute('data-label')} (${moment
		.tz(tz)
		.format('UTCZ')}) :`

	// update datetimepicker timezone and value
	picker.timeZone(tz)
	if (input.val()) {
		picker.date(picker.viewDate().tz(tz))
	}
}

export const DateUtilPlugin = {
	install(Vue) {
		Vue.prototype.$dateUtils = {
			format,
			utcFormat,
			unaryFormat,
			today,
			startOfDay,
			endOfDay,
			toDate,
			defaultDateFormat
		}
	}
}
