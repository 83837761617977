import axios from 'axios'
import logoutProcess from '@/lib/utils/session'
import { v4 as uuidV4 } from 'uuid'

const { VUE_APP_API_URL, VUE_APP_NAME, VUE_APP_VERSION } = process.env

const axiosApiClient = axios.create({
	baseURL: VUE_APP_API_URL,
	headers: {
		common: {
			'X-LLM-APPNAME': VUE_APP_NAME,
			'X-LLM-VERSION': VUE_APP_VERSION
		}
	},
	timeout: 30000
})

const token = localStorage.getItem('token')
if (token) {
	axiosApiClient.defaults.headers.common.Authorization = `Bearer ${token}`
}

axios.interceptors.request.use(config => {
	config.headers['X-Request-Id'] = uuidV4()
	return config
})

axiosApiClient.interceptors.response.use(undefined, error => {
	if (error.response && error.response.status === 401) {
		const { baseURL, url } = error.config
		const domainRegex =
			/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/g
		if (
			url.includes(VUE_APP_API_URL) ||
			(!url.match(domainRegex) && baseURL === VUE_APP_API_URL)
		) {
			logoutProcess()
		}
	}
	return Promise.reject(error)
})

export default axiosApiClient
