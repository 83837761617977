import _ from 'lodash'

// state
const state = {
	list: []
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
	addItemsToList(state, item) {
		state.list = [...state.list, item]
	},
	removeItemFromList(state, id) {
		state.list = state.list.filter(i => i.id != id)
	}
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
	addToList({ commit }, item) {
		commit('addItemsToList', item)
	},
	removeFromList({ commit }, id) {
		commit('removeItemFromList', id)
	}
}

// getters are functions.
const getters = {
	getTokenById: state => id => {
		return state.list.find(item => item.id === id)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
