const routes = [
	{
		name: 'Index',
		path: '/',
		component: () => import(/* webpackChunkName: "unsecured" */ '@/App.vue')
	},
	{
		name: 'Login',
		path: '/login',
		component: () =>
			import(
				/* webpackChunkName: "unsecured" */ '@/components/GoogleSignIn.vue'
			)
	},
	{
		name: 'Hello',
		path: '/hello',
		component: () =>
			import(/* webpackChunkName: "secured" */ '@/components/Hello.vue')
	},
	{
		name: 'Config',
		path: '/config',
		component: () =>
			import(/* webpackChunkName: "secured" */ '@/components/Config.vue')
	},
	{
		name: 'ClientList',
		path: '/clients',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ClientList.vue'
			)
	},
	{
		name: 'ClientNew',
		path: '/clients/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ClientNew.vue'
			)
	},
	{
		name: 'ClientDetails',
		path: '/clients/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ClientDetails.vue'
			)
	},
	{
		name: 'ClientEdit',
		path: '/clients/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ClientEdit.vue'
			)
	},
	{
		name: 'CrossdockList',
		path: '/crossdocks',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/Crossdock/List.vue'
			)
	},
	{
		name: 'CrossdockDetails',
		path: '/crossdocks/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/Crossdock/Details.vue'
			)
	},
	{
		name: 'DeliveriesList',
		path: '/deliveries',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/DeliveriesList.vue'
			)
	},
	{
		name: 'DeliveriesDetails',
		path: '/deliveries/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/DeliveriesDetails.vue'
			)
	},
	{
		name: 'DeliveriesEdit',
		path: '/deliveries/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/DeliveriesEdit.vue'
			)
	},
	{
		name: 'FormsList',
		path: '/forms',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormsList.vue'
			)
	},
	{
		name: 'FormsNew',
		path: '/forms/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormsNew.vue'
			)
	},
	{
		name: 'FormsDetails',
		path: '/forms/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormsDetails.vue'
			)
	},
	{
		name: 'FormsEdit',
		path: '/forms/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormsEdit.vue'
			)
	},
	{
		name: 'FormFieldsList',
		path: '/form-fields',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormFieldsList.vue'
			)
	},
	{
		name: 'FormFieldsNew',
		path: '/form-fields/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormFieldsNew.vue'
			)
	},
	{
		name: 'FormFieldsDetails',
		path: '/form-fields/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormFieldsDetails.vue'
			)
	},
	{
		name: 'FormFieldsEdit',
		path: '/form-fields/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormFieldsEdit.vue'
			)
	},
	{
		name: 'LocaleList',
		path: '/locales',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/LocaleList.vue'
			)
	},
	{
		name: 'LocaleNew',
		path: '/locales/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/LocaleNew.vue'
			)
	},
	{
		name: 'LocaleDetails',
		path: '/locales/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/LocaleDetails.vue'
			)
	},
	{
		name: 'LocaleEdit',
		path: '/locales/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/LocaleEdit.vue'
			)
	},
	{
		name: 'Locations',
		path: '/locations',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/Locations/index.vue'
			)
	},
	{
		name: 'LocationNew',
		path: '/locations/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/Locations/LocationNew.vue'
			)
	},
	{
		name: 'LocationDetails',
		path: '/locations/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/Locations/LocationDetails.vue'
			)
	},
	{
		name: 'LocationEdit',
		path: '/locations/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/Locations/LocationEdit.vue'
			)
	},
	{
		name: 'UserList',
		path: '/users',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserList.vue'
			)
	},
	{
		name: 'UserNew',
		path: '/users/new',
		component: () =>
			import(/* webpackChunkName: "secured" */ '@/components/UserNew.vue')
	},
	{
		name: 'UserDetails',
		path: '/users/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserDetails.vue'
			)
	},
	{
		name: 'UserEdit',
		path: '/users/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserEdit.vue'
			)
	},
	{
		name: 'UserTagKeysList',
		path: '/user-tag-keys',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserTagKeysList.vue'
			)
	},
	{
		name: 'UserTagKeysNew',
		path: '/user-tag-keys/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserTagKeysNew.vue'
			)
	},
	{
		name: 'UserTagKeysDetails',
		path: '/user-tag-keys/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/UserTagKeysDetails.vue'
			)
	},
	{
		name: 'WebhookList',
		path: '/webhooks',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/WebhookList.vue'
			)
	},
	{
		name: 'WebhookDetails',
		path: '/webhooks/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/WebhookDetails.vue'
			)
	},
	{
		name: 'FormSubmissionsList',
		path: '/form-submissions',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormSubmissionsList.vue'
			)
	},
	{
		name: 'FormSubmissionsNew',
		path: '/form-submissions/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormSubmissionsNew.vue'
			)
	},
	{
		name: 'FormSubmissionsExport',
		path: '/form-submissions/export',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormSubmissionsExport.vue'
			)
	},
	{
		name: 'FormSubmissionsDetails',
		path: '/form-submissions/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/FormSubmissionsDetails.vue'
			)
	},
	{
		name: 'PickupPointList',
		path: '/pickup-points',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/PickupPointList.vue'
			)
	},
	{
		name: 'PickupPointNew',
		path: '/pickup-points/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/PickupPointNew.vue'
			)
	},
	{
		name: 'PickupPointDetails',
		path: '/pickup-points/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/PickupPointDetails.vue'
			)
	},
	{
		name: 'PickupPointEdit',
		path: '/pickup-points/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/PickupPointEdit.vue'
			)
	},
	{
		name: 'OrdersList',
		path: '/orders',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrdersList.vue'
			)
	},
	{
		name: 'OrdersNew',
		path: '/orders/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrdersNew.vue'
			)
	},
	{
		name: 'OrdersInfoEdit',
		path: '/orders/info-edit',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/OrdersInfoEdit/index.vue'
			)
	},
	{
		name: 'OrdersDetails',
		path: '/orders/:id',
		component: () =>
			import(
				/* webpackChunkName: "orders" */
				'@/pages/Orders/index.vue'
			)
	},
	{
		name: 'OrdersEdit',
		path: '/orders/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/OrderEdits/index.vue'
			)
	},
	{
		name: 'OrderSpreadsheetFormatsList',
		path: '/order-spreadsheet-formats',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderSpreadsheetFormatsList.vue'
			)
	},
	{
		name: 'OrderSpreadsheetFormatsNew',
		path: '/order-spreadsheet-formats/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderSpreadsheetFormatsNew.vue'
			)
	},
	{
		name: 'OrderSpreadsheetFormatsDetails',
		path: '/order-spreadsheet-formats/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderSpreadsheetFormatsDetails.vue'
			)
	},
	{
		name: 'OrderSpreadsheetFormatsEdit',
		path: '/order-spreadsheet-formats/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderSpreadsheetFormatsEdit.vue'
			)
	},
	{
		name: 'RouteList',
		path: '/routes',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/RouteList.vue'
			)
	},
	{
		name: 'RouteNew',
		path: '/routes/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/RouteNew.vue'
			)
	},
	{
		name: 'RouteDetails',
		path: '/routes/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/RouteDetails.vue'
			)
	},
	{
		name: 'RouteEdit',
		path: '/routes/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/RouteEdit.vue'
			)
	},
	{
		name: 'SummaryItemActionsList',
		path: '/summary-item-actions',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryItemActionsList.vue'
			)
	},
	{
		name: 'SummaryItemActionsNew',
		path: '/summary-item-actions/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryItemActionsNew.vue'
			)
	},
	{
		name: 'SummaryItemActionsDetails',
		path: '/summary-item-actions/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryItemActionsDetails.vue'
			)
	},
	{
		name: 'SummaryItemActionsEdit',
		path: '/summary-item-actions/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryItemActionsEdit.vue'
			)
	},
	{
		name: 'SummaryStatusActionsList',
		path: '/summary-status-actions',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryStatusActionsList.vue'
			)
	},
	{
		name: 'SummaryStatusActionsNew',
		path: '/summary-status-actions/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryStatusActionsNew.vue'
			)
	},
	{
		name: 'SummaryStatusActionsEdit',
		path: '/summary-status-actions/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryStatusActionsEdit.vue'
			)
	},
	{
		name: 'SummaryStatusActionsDetails',
		path: '/summary-status-actions/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/SummaryStatusActionsDetails.vue'
			)
	},
	{
		name: 'CourierMenuDetail',
		path: '/courier-menu',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/CourierMenuDetail.vue'
			)
	},
	{
		name: 'CourierMenuEdit',
		path: '/courier-menu/edit',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/CourierMenuEdit.vue'
			)
	},
	{
		name: 'OrdersSpreadsheetSubmissionsList',
		path: '/orders-spreadsheet-submissions',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrdersSpreadsheetSubmissionsList.vue'
			)
	},
	{
		name: 'OrdersSpreadsheetSubmissionsDetails',
		path: '/orders-spreadsheet-submissions/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrdersSpreadsheetSubmissionsDetails.vue'
			)
	},
	{
		name: 'ApiTokenList',
		path: '/api-tokens',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ApiTokenList.vue'
			)
	},
	{
		name: 'ApiTokenNew',
		path: '/api-tokens/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ApiTokenNew.vue'
			)
	},
	{
		name: 'ApiTokenDetails',
		path: '/api-tokens/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ApiTokenDetails.vue'
			)
	},
	{
		name: 'ApiTokenEdit',
		path: '/api-tokens/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/ApiTokenEdit.vue'
			)
	},
	{
		name: 'OrderTagKeysList',
		path: '/order-tag-keys',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderTagKeysList.vue'
			)
	},
	{
		name: 'OrderTagKeysNew',
		path: '/order-tag-keys/new',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderTagKeysNew.vue'
			)
	},
	{
		name: 'OrderTagKeysDetails',
		path: '/order-tag-keys/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/components/OrderTagKeysDetails.vue'
			)
	},
	// {
	// 	name: 'MatchingRulesList',
	// 	path: '/matching-rules',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "secured" */ '@/components/MatchingRulesList.vue'
	// 		)
	// },
	// {
	// 	name: 'MatchingRulesNew',
	// 	path: '/matching-rules/new',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "secured" */ '@/components/MatchingRulesNew.vue'
	// 		)
	// },
	// {
	// 	name: 'MatchingRulesEdit',
	// 	path: '/matching-rules/edit/:id',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "secured" */ '@/components/MatchingRulesEdit.vue'
	// 		)
	// },
	// {
	// 	name: 'MatchingRulesDetails',
	// 	path: '/matching-rules/:id',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "secured" */ '@/components/MatchingRulesDetails.vue'
	// 		)
	// },
	// {
	// 	name: 'OrderMatchingStatistics',
	// 	path: '/order-matching-statistics',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "secured" */ '@/components/OrderMatchingStatistics.vue'
	// 		)
	// },
	{
		name: 'AlertTypesList',
		path: '/alert-types',
		component: () =>
			import(
				/* webpackChunkName: "alert-types" */ '@/components/AlertTypesList.vue'
			)
	},
	{
		name: 'AlertTypesNew',
		path: '/alert-types/new',
		component: () =>
			import(
				/* webpackChunkName: "alert-types" */ '@/components/AlertTypesNew.vue'
			)
	},
	{
		name: 'AlertTypesEdit',
		path: '/alert-types/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "alert-types" */ '@/components/AlertTypesEdit.vue'
			)
	},
	{
		name: 'AlertTypesDetails',
		path: '/alert-types/:id',
		component: () =>
			import(
				/* webpackChunkName: "alert-types" */ '@/components/AlertTypesDetails.vue'
			)
	},
	{
		name: 'UserGroupsList',
		path: '/user-groups',
		component: () =>
			import(
				/* webpackChunkName: "user-groups" */ '@/components/UserGroupsList.vue'
			)
	},
	{
		name: 'UserGroupsNew',
		path: '/user-groups/new',
		component: () =>
			import(
				/* webpackChunkName: "user-groups" */ '@/components/UserGroupsNew.vue'
			)
	},
	{
		name: 'UserGroupsEdit',
		path: '/user-groups/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "user-groups" */ '@/components/UserGroupsEdit.vue'
			)
	},
	{
		name: 'UserGroupsDetails',
		path: '/user-groups/:id',
		component: () =>
			import(
				/* webpackChunkName: "user-groups" */ '@/components/UserGroupsDetails.vue'
			)
	},
	{
		name: 'AlertSubscriptionsList',
		path: '/alert-subscriptions',
		component: () =>
			import(
				/* webpackChunkName: "alert-subscriptions" */ '@/components/AlertSubscriptionsList.vue'
			)
	},
	{
		name: 'AlertSubscriptionsNew',
		path: '/alert-subscriptions/new',
		component: () =>
			import(
				/* webpackChunkName: "alert-subscriptions" */ '@/components/AlertSubscriptionsNew.vue'
			)
	},
	{
		name: 'AlertSubscriptionsDetails',
		path: '/alert-subscriptions/:id',
		component: () =>
			import(
				/* webpackChunkName: "alert-subscriptions" */ '@/components/AlertSubscriptionsDetails.vue'
			)
	},
	{
		name: 'AlertsList',
		path: '/alerts',
		component: () =>
			import(
				/* webpackChunkName: "alerts" */ '@/components/AlertsList.vue'
			)
	},
	{
		name: 'AlertsNew',
		path: '/alerts/new',
		component: () =>
			import(
				/* webpackChunkName: "alerts" */ '@/components/AlertsNew.vue'
			)
	},
	{
		name: 'AlertsEdit',
		path: '/alerts/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "alerts" */ '@/components/AlertsEdit.vue'
			)
	},
	{
		name: 'AlertsDetails',
		path: '/alerts/:id',
		component: () =>
			import(
				/* webpackChunkName: "alerts" */ '@/components/AlertsDetails.vue'
			)
	},
	{
		name: 'Dashboard',
		path: '/dashboard',
		component: () =>
			import(
				/* webpackChunkName: "dashboard" */ '@/pages/Dashboard/index.vue'
			)
	},
	{
		name: 'SMS',
		path: '/sms',
		component: () =>
			import(/* webpackChunkName: "sms" */ '@/pages/SMS/index.vue')
	},
	{
		name: 'AuditLogs',
		path: '/audit-logs',
		component: () =>
			import(
				/* webpackChunkName: "auditLogs" */ '@/pages/AuditLogs/index.vue'
			)
	},
	{
		name: 'AuditLogsDetails',
		path: '/audit-logs/:id',
		component: () =>
			import(
				/* webpackChunkName: "secured" */ '@/pages/AuditLogs/details.vue'
			)
	},
	{
		name: 'ReportGeneration',
		path: '/report-generation',
		component: () =>
			import(
				/* webpackChunkName: "reportGeneration" */ '@/pages/ReportGeneration/index.vue'
			)
	},
	{
		name: 'Courier',
		path: '/couriers',
		component: () =>
			import(
				/* webpackChunkName: "couriers" */ '@/pages/Couriers/index.vue'
			)
	},
	{
		name: 'CourierNew',
		path: '/couriers/new',
		component: () =>
			import(
				/* webpackChunkName: "couriers" */ '@/pages/Couriers/CourierNew.vue'
			)
	},
	{
		name: 'CourierEdit',
		path: '/couriers/edit/:id',
		component: () =>
			import(
				/* webpackChunkName: "couriers" */ '@/pages/Couriers/CourierEdit.vue'
			)
	},
	{
		name: 'CourierDetails',
		path: '/couriers/:id',
		component: () =>
			import(
				/* webpackChunkName: "couriers" */ '@/pages/Couriers/CourierDetails.vue'
			)
	},
	{
		name: 'ShiftDashboard',
		path: '/shift-dashboard',
		component: () =>
			import(
				/* webpackChunkName: "shiftDashboard" */ '@/pages/ShiftDashboard/index.vue'
			)
	}
]

export { routes }
