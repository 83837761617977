import _ from 'lodash'
import logger from '../../../lib/utils/log'

import { getLocales } from '@/api/locales-api'

import { getCities } from '@/api/infos-api'
import { getRegions } from '@/api/regions-api'
import {
	getLocations,
	getLocation,
	updateLocation,
	createLocation
} from '@/api/locations-api'

const locationsMutation = {
	setLocationList: 'SET_LOCATION_LIST',
	setFilter: 'SET_FILTER',
	setLocationInfo: 'SET_LOCATION_INFO',
	setAvailableLocationIdList: 'SET_AVAILABLE_LOCATION_ID_LIST',
	setLocaleOptions: 'SET_LOCALE_OPTIONS'
}

const defaultFilter = {
	page: 1,
	limit: 100,
	sortBy: 'id',
	sortDirection: 'DESC'
}

const state = {
	data: [],
	total: 0,
	totalPage: 1,
	filter: defaultFilter,
	locationInfo: {},
	formData: { availableLocationIdList: [], LocaleOptions: [] }
}

const mutations = {
	[locationsMutation.setLocationList]: function (
		state,
		{ data = [], total = 0, totalPage = 1 }
	) {
		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
	},
	[locationsMutation.setFilter]: function (state, filter = {}) {
		_.set(state, 'filter', { ...state.filter, ...filter })
	},
	[locationsMutation.setLocationInfo]: function (state, locationInfo = {}) {
		_.set(state, 'locationInfo', locationInfo)
	},
	[locationsMutation.setAvailableLocationIdList]: function (
		state,
		availableLocationIdList = []
	) {
		_.set(
			state,
			'formData.availableLocationIdList',
			availableLocationIdList
		)
	},
	[locationsMutation.setLocaleOptions]: function (state, localeOptions = []) {
		_.set(state, 'formData.localeOptions', localeOptions)
	}
}

const actions = {
	async getLocations({ state, commit, dispatch }, filter = {}) {
		//	Build params with the state.filter(old state) and filter(new input)
		const filteringPagination = filter.page
			? filter
			: { ...defaultFilter, ...filter }
		const params = { ...state.filter, ...filteringPagination }

		const { data, total, page, limit } = await getLocations(params)
		const modifiedData = _.map(data, location => ({
			id: location.id,
			translations: _.map(location.translations, 'value').join(', ')
		}))
		const totalPage = total <= limit ? 1 : _.ceil(total / limit)

		await commit(locationsMutation.setLocationList, {
			data: modifiedData,
			total,
			totalPage
		})
		await dispatch('updateFilter', { ...params, page, limit })
	},
	async getLocationById({ commit }, id) {
		const resp = await getLocation(id)
		await commit(locationsMutation.setLocationInfo, resp)
	},
	updateFilter({ commit }, filter) {
		commit(locationsMutation.setFilter, filter)
	},
	resetFilter({ commit }) {
		commit(locationsMutation.setFilter, defaultFilter)
	},
	async getAvailableLocationIds({ commit }) {
		try {
			// Get existing location Id
			const existingLocations = _.map(
				_.get(await getLocations(), 'data', []),
				'id'
			)
			// Get existing city cde
			const cities = _.map(await getCities(), city => ({
				locationCode: city.city_code_map,
				cityId: city.id
			}))

			// Filter only the non-existing location with also existing in city list
			await commit(
				locationsMutation.setAvailableLocationIdList,
				_.filter(
					cities,
					city => !_.includes(existingLocations, city.locationCode)
				)
			)
		} catch (e) {
			logger.error('Error on GET Available LocationIds', e.stack)
		}
	},
	async getLocationInfoByCityId({ state, commit }, { locationCode, cityId }) {
		if (_.isEmpty(locationCode) || _.isEmpty(cityId)) {
			return {}
		}
		try {
			const {
				latitude: lat,
				longitude: lng,
				timezone
			} = _.pick(_.head(await getCities({ cityId })), [
				'latitude',
				'longitude',
				'timezone'
			])

			const countryId = parseInt(cityId) - (parseInt(cityId) % 10000)
			const countryInfo = _.find(await getRegions(), {
				countryId: countryId
			})
			if (_.isEmpty(countryInfo)) {
				return {}
			}

			const {
				defaultLanguage: locale,
				translations,
				samplePhone,
				areaCode
			} = _.pick(countryInfo, [
				'defaultLanguage',
				'translations',
				'samplePhone',
				'areaCode'
			])

			const locationInfo = {
				...state.location,
				id: locationCode,
				timezone: timezone || '',
				geo: { lat, lng, radius: null } || state.location.geo,
				locales: [
					`${_.split(locale, '_')[0]}-${_.toUpper(
						_.split(locale, '_')[1]
					)}`
				],
				translations: _.map(translations, translation => ({
					locale: `${_.split(translation.id, '_')[0]}-${_.toUpper(
						_.split(translation.id, '_')[1]
					)}`,
					value: translation.value
				})),
				phone_formats: {
					regexpIntl: `^\\${areaCode}(\\d{${samplePhone.length}})$`,
					regexpLocal: `^(\\d{${samplePhone.length}})$`,
					templateIntl: `${areaCode}{{base}}`,
					templateLocal: '{{base}}',
					codePrefix: ''
				}
			}

			await commit(locationsMutation.setLocationInfo, locationInfo)
			return locationInfo
		} catch (e) {
			logger.error('Error on GET Location Info by CityId', e.stack)
			return {}
		}
	},
	async getLocaleOptions({ commit }) {
		const resp = _.map(await getLocales({ limit: 100 }), 'id')
		await commit(locationsMutation.setLocaleOptions, resp)
	},
	async updateLocation({ commit }, location = {}) {
		return updateLocation(location.id, _.omit(location, ['id']))
	},
	async createLocation({ commit }, location = {}) {
		return createLocation(location)
	}
}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	namespaced
}
