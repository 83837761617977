import _ from 'lodash'

import { toDate } from '../../../lib/plugins/date'
import logger from '@/lib/utils/log'
import {
	exportOrdersInCSV,
	getClientList,
	getLocaleIds,
	getUserAliasTypes,
	patchOrderForCourier
	// triggerMatchmaking,
} from './ajax'
import { defaultDashboardPageLimit, mutationType, tabType } from './config'
import {
	actions as generalFilterActions,
	initialFilter as initialGeneralFilter,
	mutations as generalFilterMutations
} from './general'
import {
	actions as orderIdsFilterActions,
	initialFilter as initialOrderIdsFilter,
	mutations as orderIdsFilterMutations
} from './orderIds'
import {
	postOrderBatches,
	getOrderBatchOptions,
	postBatchOrderAssign,
	getOrderBatchDriverInfo
} from '@/api/dashbaord-api'

import { getLocations } from '@/api/locations-api'

const state = {
	data: [],
	totalPage: 1,
	total: 0,
	selectedOrders: [],
	clientList: [],
	locationList: [],
	shiftDialogOrderList: [],
	localeList: [],
	userAliasTypes: [],
	tabFilter: {
		tab: tabType.general,
		filter: {
			...initialGeneralFilter
		}
	}
}

const mutations = {
	[mutationType.setDashboard](state, { data, total }) {
		const totalPage =
			total <= defaultDashboardPageLimit
				? 1
				: _.ceil(total / defaultDashboardPageLimit)

		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
	},
	[mutationType.setSelectedOrders](state, selectedOrders) {
		_.set(state, 'selectedOrders', selectedOrders)
	},
	[mutationType.setClientList](state, clientList) {
		_.set(state, 'clientList', clientList)
	},
	[mutationType.setLocationList](state, locationList) {
		_.set(state, 'locationList', locationList)
	},
	[mutationType.setLocaleList](state, localeList) {
		_.set(state, 'localeList', localeList)
	},
	[mutationType.setTabFilter](state, tabFilter) {
		_.set(state, 'tabFilter', tabFilter)
	},
	[mutationType.setUserAliasTypes](state, aliasTypes) {
		_.set(state, 'userAliasTypes', aliasTypes)
	},
	[mutationType.setShiftDialogOrderList](state, shiftDialogOrderList) {
		_.set(state, 'shiftDialogOrderList', shiftDialogOrderList)
	},
	...generalFilterMutations,
	...orderIdsFilterMutations
}

const dashboardActions = {
	changeTab({ commit }, tab) {
		if (tab === tabType.general) {
			commit(mutationType.setTabFilter, {
				tab,
				filter: { ...initialGeneralFilter }
			})
		} else if (tab === tabType.orderIds) {
			commit(mutationType.setTabFilter, {
				tab,
				filter: { ...initialOrderIdsFilter }
			})
		} else {
			logger.info('[Backoffice][Dashboard] Invalid tab type')
		}
	},
	async getClientIdList({ commit }) {
		const clientList = await getClientList()
		commit(mutationType.setClientList, clientList)
	},
	async getLocationList({ commit }) {
		const locations = _.get(await getLocations(), 'data', [])
		commit(mutationType.setLocationList, locations)
	},
	async getLocaleIdList({ commit }) {
		const localeList = await getLocaleIds()
		commit(mutationType.setLocaleList, localeList)
	},
	async getOrderBatchOptionList({ commit }) {
		const { filter } = state.tabFilter
		const params = {
			minPickupAt: toDate(filter.minPickupAt),
			maxPickupAt: toDate(filter.maxPickupAt),
			locationId: filter.locationId
		}
		try {
			const orderBatchOptions = await getOrderBatchOptions(params)
			return orderBatchOptions
		} catch (e) {
			logger.error(
				'[Backoffice][Dashboard] Failed to get order batch option list',
				{ requestQuery: params },
				e.stack
			)
			return {
				pickupAddress: [],
				orderCount: 0
			}
		}
	},
	async postOrderBatchList({ commit }, body) {
		try {
			const orderBatches = await postOrderBatches(body)
			return orderBatches
		} catch (e) {
			logger.error(
				'[Backoffice][Dashboard] Failed to post order batch list',
				{ requestBody: body },
				e.stack
			)
			return []
		}
	},
	exportOrders({ state }, locale) {
		const { filter } = state.tabFilter
		return exportOrdersInCSV({
			locale,
			minPickupAt: toDate(filter.minPickupAt),
			maxPickupAt: toDate(filter.maxPickupAt),
			maxDeliveryBy: toDate(filter.maxDeliveryBy),
			..._.pick(filter, [
				'locationId',
				'clientId',
				'deliveryType',
				'deliveryStage',
				'deliveryStatus',
				'pickupDistrict',
				'deliveryDistrict'
			])
		})
	},
	updateSelectedOrders({ commit }, selectedOrders) {
		commit(mutationType.setSelectedOrders, selectedOrders)
	},
	patchOrderForCourier(_, { orderId, userIds }) {
		return patchOrderForCourier(orderId, userIds)
	},
	postBatchOrderAssign(_, { orderIds, userId }) {
		return postBatchOrderAssign({ orderIds, userId })
	},
	getOrderBatchDriverInfo(
		_,
		{ phone, email, name, userId, locationId, shiftStartAt }
	) {
		return getOrderBatchDriverInfo({
			phone,
			email,
			name,
			userId,
			locationId,
			shiftStartAt
		})
	},
	async getUserAliasTypes({ commit }) {
		commit(mutationType.setUserAliasTypes, await getUserAliasTypes())
	},
	// async triggerMatchMaking({ dispatch }, orderId) {
	// 	await triggerMatchmaking(orderId)
	// 	await dispatch('getDashboard')
	// },
	async getDashboard({ state, dispatch }, filter = {}) {
		const { tab } = state.tabFilter
		if (tab === tabType.general) {
			await dispatch('getDashboardByGeneralFilter', filter)
		} else if (tab === tabType.orderIds) {
			await dispatch('getDashboardByOrderIdsFilter', filter)
		}
	}
}

const actions = {
	...generalFilterActions,
	...orderIdsFilterActions,
	...dashboardActions,
	resetFilter({ commit }) {
		const defaultTabFilter = {
			tab: tabType.general,
			filter: {
				...initialGeneralFilter
			}
		}
		commit(mutationType.setTabFilter, defaultTabFilter)
	}
}

const getters = {
	tab: state => state.tabFilter.tab,
	liftedFilter: state => state.tabFilter.filter,
	locationIdList: state => state.locationList.map(({ id }) => id)
}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced
}
