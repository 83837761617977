/* eslint-disable no-console */
const log = {
	debug: (...args) => {
		console.debug(...args)
	},
	info: (...args) => {
		console.info(...args)
	},
	warning: (...args) => {
		console.warn(...args)
	},
	error: (...args) => {
		console.error(...args)
	},
	fatal: (...args) => {
		console.error(...args)
	}
}

export default log
