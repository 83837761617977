export const defaultDashboardPageLimit = 100

export const tabType = {
	general: 'GENERAL',
	orderIds: 'ORDER_IDS'
}

export const mutationType = {
	setDashboard: 'SET_DASHBOARD',
	setSelectedOrders: 'SET_SELECTED_ORDERS',
	setShiftDialogOrderList: 'SET_SHIFT_DASHBOARD_ORDERS',
	setClientList: 'SET_CLIENT_LIST',
	setLocationList: 'SET_LOCATION_LIST',
	setLocaleList: 'SET_LOCALE_LIST',
	setTabFilter: 'SET_TAB_FILTER',
	setGeneralFilter: 'SET_GENERAL_FILTER',
	setOrderIdsFilter: 'SET_ORDER_IDS_FILTER',
	setUserAliasTypes: 'SET_USER_ALIAS_TYPES'
}
