import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
	id: string
	translations: Record<string, any>[]
}} Locale
 */

/** @type {(options: {limit?: number }) => Promise<Locale[]>} */
export const getLocales = async ({ limit }) => {
	/** @type {import('axios').AxiosResponse<Locale[]>} */
	const response = await axiosApiClient({
		url: `/locales${limit ? `?limit=${limit}` : ''}`
	})
	return response.data
}
