import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
	id: string,
	clientId: string,
	tag: string,
	type: string,
	isArray: boolean,
	translations: Record<string, any>[],
	options: Record<string, any>[],
	attributes?: Record<string, any>,
	baseFormFieldId?: string,
	createdAt: Date,
  updatedAt: Date
}} FormField
 */

/** @type {(params: {id?: string, tag?: string, type?: string}) => Promise<FormField[]>} */
export const getFormFields = async (params = {}) => {
	/** @type {import('axios').AxiosResponse<FormField[]>} */
	const response = await axiosApiClient.get(`form-fields`, { params })
	return response.data
}

/** @type {() => Promise<FormField[]>} */
export const getFormReasons = async () => {
	const [failure, success] = await Promise.all([
		getFormFields({ tag: 'REASONS_FAILURE*' }),
		getFormFields({ tag: 'REASONS_SUCCESS*' })
	])
	return failure.concat(success)
}
