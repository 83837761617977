import _ from 'lodash'
import { actions as exportOpsUseActions } from './exportOpsUseReport'
import { actions as generateCourierActions } from './generateCourierReport'
import { actions as retrievePreviouslyGeneratedActions } from './retrievePreviouslyGeneratedReport'
import { getPollingCourierReport } from './ajax'
import { getDriverUseReport } from '@/api/driver-payment-report-api'
import * as Sentry from '@sentry/vue'

const reportGenerationMutation = {
	setIsPolling: 'SET_IS_POLLING'
}

const DRIVER_USE_REPORT_POLLING_INTERVAL = 30000 // 30 secs
const DRIVER_USE_REPORT_MAX_POLLING_TIMEOUT = 6000000 // 10 mins

const state = {
	isPolling: false
}

const mutations = {
	[reportGenerationMutation.setIsPolling]: function (state, isPolling) {
		_.set(state, 'isPolling', isPolling)
	}
}

const actions = {
	...exportOpsUseActions,
	...generateCourierActions,
	...retrievePreviouslyGeneratedActions,
	async pollingDownloadCourierReports({ commit }, uuid) {
		//	Set the polling action status on
		await commit(reportGenerationMutation.setIsPolling, true)

		//	Get the polling result and turn off the polling status
		await commit(
			reportGenerationMutation.setIsPolling,
			await new Promise(resolve => {
				const interval = setInterval(async () => {
					if (await downloadCourierReports(uuid)) {
						clearInterval(interval)
						resolve(false)
					}
				}, 3000)
			})
		)
	},
	async pollingDriverUseReports({ commit }, id) {
		await commit(reportGenerationMutation.setIsPolling, true)

		let callCount = 0
		const isPolling = await new Promise(resolve => {
			const timer = setInterval(async () => {
				if (
					callCount * DRIVER_USE_REPORT_POLLING_INTERVAL <
					DRIVER_USE_REPORT_MAX_POLLING_TIMEOUT
				) {
					callCount++

					try {
						const data = await getDriverUseReport(id)

						if (data.status > 0) {
							clearInterval(timer)
							if (data.status === 1) {
								window.open(data.downloadUrl, '_blank')
							} else if (data.status === 2) {
								window.alert('Report generation failed!')
							}
							clearInterval(timer)
							resolve(false)
						}
					} catch (e) {
						const statusCode = e.response?.status
						if (statusCode === 404) {
							window.alert('Report Not Found!')
						} else if (statusCode === 422) {
							window.alert(e.response.data.detail)
						} else {
							Sentry.captureMessage(
								'PollingDriverUseReports::Unknown Error',
								{
									level: 'error',
									extra: {
										error: { reportId: id, stack: e.stack }
									}
								}
							)
							window.alert(
								'Cannot generate report with unknown error'
							)
						}
						clearInterval(timer)
						resolve(false)
					}
				} else {
					window.alert('Report generation timeout, please try again')
					clearInterval(timer)
					resolve(false)
				}
			}, DRIVER_USE_REPORT_POLLING_INTERVAL)
		})
		await commit(reportGenerationMutation.setIsPolling, isPolling)
	}
}

async function downloadCourierReports(uuid) {
	//	Get the generated report by uuid
	const response = await getPollingCourierReport(uuid)
	if (response.status === 204) {
		return false
	} // not yet generated
	if (response.status === 200) {
		// generated
		if (
			!_.isEmpty(_.get(response, 'data')) &&
			_.get(response, 'data') !== 'failed'
		) {
			window.open(response.data)
		} else {
			alert('Download Courier Reports Failed')
		}
	}
	return true
}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	namespaced
}
