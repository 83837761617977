import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';
import Vue from 'vue'

export function installQuasar() {
	Vue.use(Quasar, {
		plugins: {
			Notify
		}
	})
}
