import _ from 'lodash'
import axiosApiClient from './axiosApiClient'

/**
 * @typedef {import('./users-api.js').User} User
 * @typedef {{
	id: number,
	clientId: string,
	address: Record<string, any>[],
	locationId: string,
	contacts: Record<string, any>[],
	pickupAt: Date,
	notes: string[],
	extras: Record<string, any>[],
	status: string,
	parcelNum: number,
	totalWeight: string,
	createdAt: Date,
	updatedAt: Date,
	deletedAt: Date,
	attributes: Record<string, any>,
	deliveryBy: Date,
	deliveryStage: string,
	pickupDistrict: string,
	deliveryDistrict: string,
	pickupCoordinate: string,
	deliveryCoordinate: string,
	distance: number,
	clientRefs: Record<string, string>[],
	userIds: number[],
	users: User[],
	tags: Record<string, any>[],
	changeLogs: Order[],
	shareLink: string
}} Order */

/** @type {(id) => Promise<Order>} */
export const getOrder = async orderId => {
	/** @type {import('axios').AxiosResponse<Order>} */
	const response = await axiosApiClient.get(`/orders/${orderId}`)
	return _.get(response, 'data')
}

/** @type {(orderDisplayId: string, pickupAt: string, deliveryBy: string) => Promise<Order>} */
export const editApiV3Order = async (orderDisplayId, pickupAt, deliveryBy) => {
	/** @type {import('axios').AxiosResponse<Order>} */
	const response = await axiosApiClient({
		method: 'PATCH',
		url: `/backoffice/v1/orders/${orderDisplayId}`,
		data: {
			pickupAt,
			deliveryBy
		}
	})
	return response.data
}
