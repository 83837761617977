import { postCourierReports } from './ajax'

export const actions = {
	async generateCourierReports({ commit }, filter) {
		const { location, courierId, selectedPeriod } = filter
		return await postCourierReports(
			location,
			courierId,
			selectedPeriod,
			false
		)
	}
}
