import Vue from 'vue'
import Vuex from 'vuex'

import apiTokens from './modules/apiTokens'
import deliveriesSubmissionList from './modules/deliveriesSubmissionList'
import sms from './modules/sms'
import dashboard from './modules/dashboard'
import auditLogs from './modules/auditLogs'
import reportGeneration from './modules/reportGeneration'
import couriers from './modules/couriers'
import locations from './modules/locations'
import orders from '@/store/modules/orders'
import shiftDashboard from '@/store/modules/shiftDashboard'
import ordersInfoEdit from '@/store/modules/ordersInfoEdit'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		deliveriesSubmissionList,
		apiTokens,
		dashboard,
		sms,
		auditLogs,
		reportGeneration,
		couriers,
		locations,
		orders,
		shiftDashboard,
		ordersInfoEdit
	},
	strict: debug
})
