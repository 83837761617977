import _ from 'lodash'

import { endOfDay, startOfDay, toDate } from '@/lib/plugins/date'
import logger from '@/lib/utils/log'
import { DELIVERY_STATUS } from '@/lib/utils/constants'
import { getDashboardList } from './ajax'
import { defaultDashboardPageLimit, mutationType, tabType } from './config'

export const initialFilter = {
	deliveryType: null,
	deliveryStage: null,
	deliveryStatus: 'AWAITS_DRIVER',
	minPickupAt: startOfDay(),
	maxPickupAt: endOfDay(),
	maxDeliveryBy: endOfDay(),
	deliveryDistrict: null,
	pickupDistrict: null,
	locationId: null,
	clientId: null,
	limit: defaultDashboardPageLimit,
	sortBy: 'id',
	sortDirection: 'DESC',
	page: 1
}

export const mutations = {
	[mutationType.setGeneralFilter](state, filter = {}) {
		if (state.tabFilter.tab === tabType.general) {
			_.set(state, 'tabFilter.filter', {
				...state.tabFilter.filter,
				...filter
			})
			return
		}

		logger.info(
			`[Backoffice][Dashboard] Mismatch tab type, expected: ${tabType.general}`
		)
	}
}

export const actions = {
	updateGeneralFilter({ commit }, filter = {}) {
		commit(mutationType.setGeneralFilter, filter)
	},
	resetGeneralFilter({ commit }) {
		commit(mutationType.setGeneralFilter, initialFilter)
	},
	async getDashboardByGeneralFilter(
		{ commit, dispatch, state },
		filter = {}
	) {
		const currentFilter = state.tabFilter.filter
		const params = {
			...currentFilter,
			...filter
		}
		const transformedFilter = {
			...params,
			minPickupAt: toDate(params.minPickupAt),
			maxPickupAt: toDate(params.maxPickupAt),
			maxDeliveryBy: toDate(params.maxDeliveryBy)
		}
		const { data, total, page, limit } = await getDashboardList(
			transformedFilter
		)
		await dispatch('updateGeneralFilter', { ...params, page, limit })
		await dispatch('updateSelectedOrders', [])
		commit(mutationType.setDashboard, { data, total })
	},
	async getDashboardOrdersByShift({ commit, dispatch, state }, filter) {
		const { data } = await getDashboardList({
			locationId: filter.locationId,
			minPickupAt: toDate(filter.startAt),
			maxPickupAt: toDate(filter.endAt),
			deliveryStatus: DELIVERY_STATUS.AWAITS_DRIVER,
			pickupDistrict: filter.pickupDistrict,
			deliveryDistrict: filter.deliveryDistrict,
			clientId: filter.clientId,
			limit: 500,
			sortBy: 'id',
			sortDirection: 'DESC',
			page: 1
		})
		commit(mutationType.setShiftDialogOrderList, data)
	}
}
