/* global _ */
import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
	id: string
	countryId: string
	isOpen: number
	isDriverOpen: number
	defaultLanguage: string
	defaultTimezone: string
	region: string
	datacenter: string
	continent: string
	flag: string
	areaCode: string
	samplePhone: string
	lat: number
	lng: number
	enableEnv: Record<string, string>[]
	translations: Record<string, any>[]
	languages: translations: Record<string, any>[]
	currency: Record<string, any>
	upi:string
	dmeta:string
	umeta:string
	viewOtherCities:string
	measurementSystem:string
}} Region */

/** @type {() => Promise<Record<string, Region>[]>} */
export const getRegions = async () => {
	/** @type {import('axios').AxiosResponse<Record<string, Region>[]>} */
	const response = await axiosApiClient.get('/v1/regions')
	return _.get(response, 'data.data')
}
