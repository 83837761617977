/* global */
import './assets/css/main.scss'

import logoutProcess from '@/lib/utils/session'
import router from '@/router'
import store from '@/store'
import Clipboard from 'v-clipboard'
import Vue from 'vue'
import {
	MdBadge,
	MdButton,
	MdCard,
	MdDialog,
	MdDialogAlert,
	MdIcon,
	MdProgress
} from 'vue-material/dist/components'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/vue'
import jsonwebtoken from 'jsonwebtoken'
import { DateUtilPlugin } from './lib/plugins/date'
import { ValidatorPlugin } from './lib/plugins/validatorPlugin'
import { installQuasar } from './lib/utils/quasar'
import { initRolePermissionList } from './permission/rolePermission'

installQuasar()

Vue.use(MdBadge)
Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdProgress)
Vue.use(MdDialogAlert)
Vue.use(MdIcon)
Vue.use(MdCard)
Vue.use(Clipboard)
Vue.use(ValidatorPlugin)
Vue.use(DateUtilPlugin)
Vue.use(VueRouter)
Vue.config.productionTip = false

if (process.env.VUE_APP_ENV !== 'ldev') {
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		environment: process.env.VUE_APP_ENV,
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: ['localhost', /^\//],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			}),
			new Sentry.Replay()
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		release: process.env.VUE_APP_VERSION,
		ignoreErrors: [
			'Request failed with status code 422',
			'$ is not defined',
			/Client ID\(s\) \w* don't exist./,
			/Avoided redundant navigation to current location/
		]
	})
}

new Vue({
	name: 'BackOffice',
	el: '#app',
	router,
	store,
	components: {
		ErrorBoundary: () => import('./components/ErrorBoundary.vue')
	},
	data() {
		const prodEnvs = ['pre', 'prd']
		const token = localStorage.getItem('token')
		let userId = ''
		if (token) {
			const parsedToken = jsonwebtoken.decode(token, {
				complete: true
			})
			userId = parsedToken.payload.sub
		}
		return {
			userId,
			email: localStorage.getItem('email'),
			userLink: `#/users/${userId}`,
			env: prodEnvs.includes(process.env.VUE_APP_ENV)
				? 'prod'
				: 'nonProd',
			isInitialised: false
		}
	},
	async mounted() {
		if (this.userId) {
			Sentry.setUser({
				id: this.userId,
				email: this.email
			})
		}

		const { VUE_APP_ENV, VUE_APP_GOOGLE_CLIENT_ID, VUE_APP_VERSION } =
			process.env

		if (VUE_APP_ENV !== 'prd') {
			document.title = `${VUE_APP_ENV.toUpperCase()} Back Office ${VUE_APP_VERSION}`
		}

		if (localStorage.getItem('token')) {
			await initRolePermissionList()
		}
		this.isInitialised = true
	},
	methods: {
		async logout() {
			logoutProcess()
		}
	}
})
