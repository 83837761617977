import _ from 'lodash'
import axiosApiClient from '../api/axiosApiClient'
let rolePermission = {}

//	The list of endpoint model that not directly match the page in frontend
const specialEndPoint = ['order-events', 'order-imports', 'reports']

const initRolePermissionList = async () => {
	// GET API role permissions
	try {
		const response = await axiosApiClient({
			url: `/role-permissions`,
			timeout: 3000
		})

		for (const [endPoint, access] of Object.entries(response.data)) {
			await commmonEndPointAccessPermissionTransfer(endPoint, access)
		}
		await specialEndPointPermissionTransfer(response.data)
	} catch (error) {
		alert('Failed to load permissions: ' + error.message)
	}
}

const updateSingleRolePermissionList = async modelName => {
	try {
		// GET API role permissions
		const response = await axiosApiClient({
			url: `/role-permissions?modelName=${modelName}`
		})
		for (const [endPoint, access] of Object.entries(response.data)) {
			await commmonEndPointAccessPermissionTransfer(endPoint, access)
		}
		await specialEndPointPermissionTransfer(response.data)
	} catch (error) {
		alert('Failed to load permissions: ' + error.message)
	}
}

const commmonEndPointAccessPermissionTransfer = async (
	modelName,
	endPointAccess
) => {
	if (specialEndPoint.includes(modelName)) {
		return
	}
	if (!rolePermission) {
		rolePermission = {}
	}

	if (endPointAccess) {
		if (endPointAccess.length !== 0 && !rolePermission[modelName]) {
			rolePermission[modelName] = []
		}

		endPointAccess.forEach(ePA => {
			switch (ePA) {
				case 'get':
					break
				case 'getById':
					rolePermission[modelName].push('detail')
					break
				case 'post':
					rolePermission[modelName].push('new')
					break
				case 'put':
				case 'patch':
					rolePermission[modelName].push('edit')
					break
				case 'delete':
					rolePermission[modelName].push('delete')
					break
			}
		})
	}
	rolePermission[modelName] = [...new Set(rolePermission[modelName])]
}

const specialEndPointPermissionTransfer = async accessCollections => {
	if (accessCollections) {
		const specialModelList = {
			'dashboard:detail': ['deliveries:getCount', 'orders:get'],
			'order-matching-statistics:': [
				'deliveries:getCount',
				'orders:matchingStats'
			],
			'orders-spreadsheet-submissions:detail': [
				'deliveries:getCount',
				'order-imports:get'
			]
		}
		for (const [modelName, requiredEndpoints] of Object.entries(
			specialModelList
		)) {
			let accessible = true
			for (let i = 0; i < requiredEndpoints.length; ++i) {
				const requiredModel = requiredEndpoints[i].substring(
					0,
					requiredEndpoints[i].search(':')
				)
				const requiredModelEndpoints = requiredEndpoints[i]
					.substring(requiredEndpoints[i].search(':') + 1)
					.split(',')

				if (
					!accessCollections[requiredModel] ||
					requiredModelEndpoints.filter(
						ep => !accessCollections[requiredModel].includes(ep)
					).length !== 0
				) {
					accessible = false
					break
				}
			}

			if (accessible) {
				rolePermission[modelName.substring(0, modelName.search(':'))] =
					modelName.search(':') !== -1
						? modelName
								.substring(modelName.search(':') + 1)
								.split(',')
						: []
			}
		}
	}
}

const rolePermissionCheck = (moduleName, action) => {
	if (
		rolePermission[moduleName] &&
		(action === '' || rolePermission[moduleName].includes(action))
	) {
		return true
	}
	return false
}

const getRolePermissions = async moduleName => {
	if (moduleName) {
		return { [moduleName]: rolePermission[moduleName] }
	}
	return rolePermission
}

export {
	initRolePermissionList,
	updateSingleRolePermissionList,
	rolePermissionCheck,
	getRolePermissions
}
