import _ from 'lodash'
import { getEditOrdersInfo } from '@/api/orders-info-edit-api'

const ordersInfoEditMutation = {
	setIsPolling: 'SET_IS_POLLING',
	setProgressMessage: 'SET_PROGRESS_MESSAGE',
	setSignedUrl: 'SET_SIGNED_URL'
}

const progressMessageMap = new Map([
	['start', 'Processing...'],
	[1, 'Orders successfully updated.'], // 1. success order info edit request status
	[2, 'Failed to update order info'], // 2. failed order info edit request status
	['timeout', 'Update timeout, unable to get update progress.'],
	['notfound', 'Cannot found the change request']
])

const POLLING_INTERVAL = 10000 // 10 sec
const MAX_POLLING_TIMEOUT = 600000 // 10 mins

const state = {
	isPolling: false,
	signedUrl: '',
	progressMessage: ''
}

const mutations = {
	[ordersInfoEditMutation.setIsPolling]: function (state, isPolling) {
		_.set(state, 'isPolling', isPolling)
	},
	[ordersInfoEditMutation.setProgressMessage]: function (state, message) {
		_.set(state, 'progressMessage', message)
	},
	[ordersInfoEditMutation.setSignedUrl]: function (state, url) {
		_.set(state, 'signedUrl', url)
	}
}

const actions = {
	async pollingDownloadOrderInfoEditCsv({ commit }, id) {
		commit(ordersInfoEditMutation.setIsPolling, true)
		commit(
			ordersInfoEditMutation.setProgressMessage,
			progressMessageMap.get('start')
		)
		commit(ordersInfoEditMutation.setSignedUrl, '')

		let callCount = 0
		const isPolling = await new Promise(resolve => {
			const timer = setInterval(async () => {
				if (callCount * POLLING_INTERVAL < MAX_POLLING_TIMEOUT) {
					callCount++

					try {
						const { status, signedUrl } = await getEditOrdersInfo(
							id
						)

						if (status > 0) {
							commit(
								ordersInfoEditMutation.setProgressMessage,
								progressMessageMap.get(status)
							)
							clearInterval(timer)
							if (status === 1) {
								commit(
									ordersInfoEditMutation.setSignedUrl,
									signedUrl
								)
							}
							resolve(false)
						}
					} catch (e) {
						if (e.response.status === 404) {
							commit(
								ordersInfoEditMutation.setProgressMessage,
								progressMessageMap.get('notfound')
							)
							clearInterval(timer)
							resolve(false)
						}
					}
				} else {
					commit(
						ordersInfoEditMutation.setProgressMessage,
						progressMessageMap.get('timeout')
					)
					clearInterval(timer)
					resolve(false)
				}
			}, POLLING_INTERVAL)
		})
		commit(ordersInfoEditMutation.setIsPolling, isPolling)
	},
	resetState({ commit }) {
		commit(ordersInfoEditMutation.setProgressMessage, '')
		commit(ordersInfoEditMutation.setSignedUrl, '')
		commit(ordersInfoEditMutation.setIsPolling, false)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
