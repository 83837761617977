import _ from 'lodash'
import axiosApiClient from './axiosApiClient'

/**
 * @typedef {import('./users-api.js').User} User
 * @typedef {{
	id: number,
	clientId: string,
	address: Record<string, any>[],
	locationId: string,
	contacts: Record<string, any>[],
	pickupAt: Date,
	notes: string[],
	extras: Record<string, any>[],
	status: string,
	parcelNum: number,
	totalWeight: string,
	createdAt: Date,
	updatedAt: Date,
	deletedAt: Date,
	attributes: Record<string, any>,
	deliveryBy: Date,
	deliveryStage: string,
	pickupDistrict: string,
	deliveryDistrict: string,
	pickupCoordinate: string,
	deliveryCoordinate: string,
	distance: number,
	clientRefs: Record<string, string>[],
	userIds: number[],
	users: User[],
	tags: Record<string, any>[],
	changeLogs: Order[],
	shareLink: string
}} Order */

/** @type {(options: {locationId: string, pickupCoordinate: string, numOfCouriers: number, minPickupAt:string, maxPickupAt: string}) => Promise<Order[][]>} */

export async function postOrderBatches(data) {
	return _.get(
		await axiosApiClient({
			method: 'POST',
			url: `/backoffice/v1/order-batches`,
			data
		}),
		'data.data',
		[]
	)
}

/**
 * @typedef {{
	address: string,
	coordinate: string
}} AddressInfo */

/** @type {(options: {locationId: string, minPickupAt: string, maxPickupAt: string}) => Promise<AddressInfo[]>} */

export async function getOrderBatchOptions(params) {
	return _.get(
		await axiosApiClient({
			method: 'GET',
			url: '/backoffice/v1/order-batch-options',
			params
		}),
		'data',
		{
			pickupAddress: [],
			orderCount: 0
		}
	)
}

/** @type {(options: {phone?: string, email?: string, name?: string, userId?: number, locationId: string, shiftStartAt: Date})} */
export async function getOrderBatchDriverInfo(params) {
	return _.get(
		await axiosApiClient({
			method: 'GET',
			url: '/backoffice/v1/assignment-couriers',
			params
		}),
		'data',
		[]
	)
}

/** @type {(options: {orderIds: number[], userId: number,})} */
export async function postBatchOrderAssign(params) {
	return await axiosApiClient({
		method: 'POST',
		url: `/backoffice/v1/assign-orders`,
		data: { ...params }
	})
}
