import _ from 'lodash'
import S from 'string'
import moment from 'moment-timezone'
import { endOfDay, startOfDay } from '@/lib/plugins/date'
import { getAuditLogActions, getAuditLogs } from '@/api/auditLog-api'

const auditLogMutation = {
	setAuditLogActionList: 'SET_AUDIT_LOG_ACTION_LIST',
	setAuditLog: 'SET_AUDIT_LOG',
	setFilter: 'SET_FILTER'
}

const defaultFilter = {
	userId: null,
	orderId: null,
	deliveryId: null,
	orderRef: null,
	parcelRef: null,
	action: null,
	status: null,
	minCreatedAt: startOfDay(),
	maxCreatedAt: endOfDay(),
	page: 1,
	limit: 100,
	sortBy: 'createdAt',
	sortDirection: 'DESC'
}

const defaultOptionList = {
	actionOptions: [],
	statusOptions: [
		{ label: 'ALL', value: '' },
		{ label: 'SUCCEED', value: 'SUCCEED' },
		{ label: 'FAILED', value: 'FAILED' },
		{ label: 'IN_PROGRESS', value: 'IN_PROGRESS' }
	]
}

const state = {
	data: [],
	total: 0,
	totalPage: 1,
	filter: defaultFilter,
	optionList: defaultOptionList
}

const mutations = {
	[auditLogMutation.setAuditLogActionList]: function (
		state,
		{ actionList = [] }
	) {
		const groupedActionList = []
		const actionOptions = [{ label: 'ALL', value: '' }]
		// organize into groups if actionList is grouped
		if (!_.isArray(actionList)) {
			for (const [group, actions] of _.toPairs(actionList)) {
				const header = _.toUpper(S(group).humanize().s)
				const actionGroup =
					group === 'UNKNOWN'
						? actions
						: [
								{
									label: `--${header}--`,
									value: 'HEADER',
									disable: true
								},
								...actions
						  ]
				groupedActionList.push(...actionGroup)
			}
		}
		const allActions = !_.isEmpty(groupedActionList)
			? groupedActionList
			: actionList
		actionOptions.push(...allActions)
		_.set(state, 'optionList.actionOptions', actionOptions)
	},
	[auditLogMutation.setAuditLog]: function (
		state,
		{ data = [], total = 0, totalPage = 1 }
	) {
		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
	},
	[auditLogMutation.setFilter]: function (state, filter = {}) {
		_.set(state, 'filter', { ...state.filter, ...filter })
	}
}

const actions = {
	async getActionList({ commit }) {
		const resp = await getAuditLogActions({ grouping: true })
		const actionList = _.get(resp, 'actionList', [])
		commit(auditLogMutation.setAuditLogActionList, { actionList })
	},
	async getAuditLogs({ state, commit, dispatch }, filter = {}) {
		//	Build params with the state.filter(old state) and filter(new input)
		const filteringPagination = filter.page
			? filter
			: { ...defaultFilter, ...filter }
		const params = { ...state.filter, ...filteringPagination }

		const { data, total, page, limit } = await getAuditLogs(
			auditLogFilterMapping(params)
		)
		const totalPage = total <= 100 ? 1 : _.ceil(total / state.filter.limit)

		commit(auditLogMutation.setAuditLog, { data, total, totalPage, page })
		await dispatch('updateFilter', { ...params, page, limit })
	},
	updateFilter({ commit }, filter) {
		commit(auditLogMutation.setFilter, filter)
	},
	resetFilter({ commit }) {
		commit(auditLogMutation.setFilter, defaultFilter)
	}
}

const auditLogFilterMapping = ({
	page = 1,
	limit,
	minCreatedAt,
	maxCreatedAt,
	...params
}) => {
	const conditions = { page, limit }

	if (minCreatedAt) {
		//	minCreatedAt
		conditions['minCreatedAt'] = moment(minCreatedAt)
			.utcOffset(0)
			.format('YYYY-MM-DD HH:mm')
	}
	if (maxCreatedAt) {
		//	maxCreatedAt
		conditions['maxCreatedAt'] = moment(maxCreatedAt)
			.utcOffset(0)
			.format('YYYY-MM-DD HH:mm')
	}

	//	userId, orderId, deliveryId, orderRef, parcelRef, action, status
	_.forEach(params, (value, index) => {
		if (params[index]) {
			conditions[index] = value
		}
	})
	return conditions
}

const getters = {}

const namespaced = true

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced
}
