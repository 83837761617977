/* global moment _ */
import axiosApiClient from './axiosApiClient'
import logger from '../lib/utils/log'

/**
 * @typedef {{
	id: string
	translations: Record<string, any>[]
	locales: string[]
	geo?: Record<string, any>
	phone_formats?: Record<string, any>
	attributes?: Record<string, any>
	timezone: string
}} Location
 */

/** @typedef {{ limit: number, page: number, total: number, data: Location[] }} LocationPaginatedResponse */

/** @type {(options: {limit: number, page: number, ...any}) => Promise<LocationPaginatedResponse>} */
export const getLocations = async options => {
	try {
		/** @type {import('axios').AxiosResponse<LocationPaginatedResponse>} */
		const response = await axiosApiClient({
			url: '/v1/locations',
			params: {
				...(_.omitBy(options, _.isNull) || null)
			}
		})

		return response.data
	} catch (e) {
		logger.error(
			'Error on GET Location List',
			e.response?.data?.errors || e.stack
		)
		return {}
	}
}

/** @type {(locationId: string, params?: any) => Promise<Location | {}>} */
export const getLocation = async (locationId, params = {}) => {
	try {
		/** @type {import('axios').AxiosResponse<Location | {}>} */
		const response = await axiosApiClient.get(
			`/v1/locations/${locationId}`,
			{
				params
			}
		)
		return response.data
	} catch (e) {
		logger.error(
			`Error on GET single Location ${locationId}`,
			e.response?.data?.errors || e.stack
		)
		return {}
	}
}

/** @type {(locationId: string, fallback: string) => Promise<string>} */
export const getLocationTimezone = async (
	locationId,
	fallback = moment.tz.guess()
) => {
	try {
		const location = await getLocation(locationId)
		return _.get(location, 'timezone', fallback)
	} catch (e) {
		logger.info(`Can't get location of ${locationId}`)
		return fallback
	}
}

/** @type {(locationId: string, location: Location) => Promise<Location | {}>} */
export const updateLocation = async (locationId, location) => {
	try {
		return await axiosApiClient({
			method: 'PATCH',
			url: `/v1/locations/${locationId}`,
			data: location
		})
	} catch (e) {
		logger.error(
			`Error on update location`,
			`locationId: ${locationId}`,
			`location: ${location}`,
			e.response?.data?.errors || e.stack
		)
		throw e
	}
}

/** @type {(location: Location) => Promise<Location | {}>} */
export const createLocation = async location => {
	try {
		return await axiosApiClient({
			method: 'POST',
			url: `/v1/locations`,
			data: { ...location, shiftGenerationEnabled: false }
		})
	} catch (e) {
		logger.error(
			'Error on create location',
			`location: ${location}`,
			e.response?.data?.errors || e.stack
		)
		throw e
	}
}

/** @type {(locationId: string) => Promise<Location | {}>} */
export const deleteLocation = async locationId => {
	try {
		/** @type {import('axios').AxiosResponse<Location | {}>} */
		const response = await axiosApiClient.delete(
			`/v1/locations/${locationId}`
		)
		return response.data
	} catch (e) {
		logger.error(
			`Error on DELETE single Location ${locationId}`,
			e.response?.data?.errors || e.stack
		)
		return {}
	}
}
