import _ from 'lodash'
import moment from 'moment-timezone'
import axiosApiClient from '../../../api/axiosApiClient'
import logger from '../../../lib/utils/log'

export async function getRealTimeCourierReportByCourierId(
	locationTimezone,
	locationId,
	courierId,
	startDate,
	endDate
) {
	//	Export the real time report for courier
	try {
		const params = {
			locationTimezone,
			locationId,
			startTime: startDate,
			endTime: endDate
		}
		const response = await axiosApiClient.get(
			`/driver-payment-reports/real-time/${courierId}/`,
			{ params, responseType: 'blob' }
		)

		//	response handling
		const data = _.get(ajaxErrorHandling(response), 'data')
		if (data) {
			const blob = new Blob([_.get(response, 'data')], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			})

			//	Get file name
			const contentDisposition = _.get(
				response,
				'headers.content-disposition'
			)
			const fileName = contentDisposition
				? contentDisposition.split('=')[1]
				: 'exportFile.xlsx'

			//	Download with file name
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			link.click()
			URL.revokeObjectURL(link.href)
		}
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/real-time/ (courierId: ${courierId}, startDate: ${startDate}, endDate: ${endDate})`,
			null,
			e.stack
		)
	}
}

export async function postCourierReports(
	location,
	courierId,
	periodStartDate,
	isAuto = false
) {
	//	Post the courier report request
	try {
		const params = {
			startTime: periodStartDate,
			isAuto,
			locationId: _.get(location, 'id'),
			locationTimezone: _.get(location, 'timezone')
		}

		if (courierId) {
			_.set(params, 'courierId', courierId)
		}
		const response = await axiosApiClient.post(
			'/driver-payment-reports/',
			params
		)

		//	response handling
		return _.get(ajaxErrorHandling(response), 'data', null)
	} catch (e) {
		logger.error(
			`Error on POST /driver-payment-reports/ (auto: ${isAuto})`,
			null,
			e.stack
		)
	}
}

export async function getPollingCourierReport(uuid) {
	//	Polling by the uuid
	try {
		return await axiosApiClient.get(
			`/driver-payment-reports/polling/${uuid}/`
		)
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/polling/ (uuid: ${uuid})`,
			null,
			e.stack
		)
	}
}

export async function getListCourierReport(location) {
	//	Get the period list option
	try {
		const timezone = _.get(location, 'timezone')
		const response = await axiosApiClient.get(
			'/driver-payment-reports/list/',
			{
				params: {
					locationId: _.get(location, 'id')
				}
			}
		)

		//	response handling
		const data = _.get(ajaxErrorHandling(response), 'data', [])
		if (data.length !== 0) {
			//	Build the option list for periodList
			//	the each month will separate as 'YYYY-MM (1H:1-15)' OR 'YYYY-MM  (2H:16-EoM)'
			const reportsList = data.map(d => {
				const dateWithTimezone = moment(d).tz(timezone)
				return {
					label: `${dateWithTimezone.format('YYYY-MM')} ${
						parseInt(dateWithTimezone.format('D')) > 15
							? '(2H:16-EoM)'
							: '(1H:1-15)'
					}`,
					value: `${dateWithTimezone
						.utc()
						.format('YYYY-MM-DD HH:mm Z')}`
				}
			})
			return reportsList
		}
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/list/`,
			null,
			e.stack
		)
	}
	return []
}

export async function getSingleCourierReportByCourierId(
	courierId,
	startDate,
	location
) {
	//	Get the single courier reports
	try {
		const response = await axiosApiClient.get(
			`/driver-payment-reports/single/${courierId}/`,
			{
				params: {
					startTime: startDate,
					locationId: _.get(location, 'id')
				}
			}
		)

		//	response handling
		return ajaxErrorHandling(response)
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/single/ (courier: ${courierId}, startDate: ${startDate}, location: ${location})`,
			null,
			e.stack
		)
	}
}

export async function getBulkCourierReports(startDate, location) {
	//	Get the generated reports for all courier
	try {
		const response = await axiosApiClient.get(
			`/driver-payment-reports/bulk/`,
			{
				params: {
					startTime: startDate,
					locationId: _.get(location, 'id')
				}
			}
		)

		//	response handling
		return ajaxErrorHandling(response)
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/bulk/ (startDate: ${startDate}, location: ${location})`,
			null,
			e.stack
		)
	}
}

export async function getLatestCourierReports(startDate, location) {
	//	Get the latest reports for all courier
	try {
		const response = await axiosApiClient.get(
			`/driver-payment-reports/latest/`,
			{
				params: {
					startTime: startDate,
					locationId: _.get(location, 'id')
				}
			}
		)

		//	response handling
		return ajaxErrorHandling(response)
	} catch (e) {
		logger.error(
			`Error on GET /driver-payment-reports/latest/ (startDate: ${startDate})`,
			null,
			e.stack
		)
	}
}

//	response alert handling
function ajaxErrorHandling(response) {
	if (_.get(response, 'status') === 200) {
		return response
	} else if (_.get(response, 'status') === 204) {
		alert('No report found')
	} else {
		alert(
			`status: ${_.get(response, 'status')} body: ${_.get(
				response,
				'body'
			)}`
		)
	}
	return null
}
