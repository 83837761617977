import _ from 'lodash'

// state
const state = {
	list: []
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
	addItemsToList(state, items) {
		state.list = [
			...state.list,
			...items.filter(
				newItem =>
					!state.list.some(listItem => listItem.id === newItem.id)
			)
		]
	},
	removeItemFromList(state, id) {
		state.list = state.list.filter(i => i.id !== id)
	}
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
	addToList({ commit }, item) {
		const items = _.castArray(item)
		const tempArray = [...state.list].concat(...items)
		const orderNos = _.flatten(
			tempArray.map(i =>
				i.deliveryDetails.submissionData['ORDER_NO'].map(v => v.value)
			)
		)
		if (_.uniq(orderNos).length > 1) {
			alert('Only one unique Order No. is allowed')
			return
		}
		commit('addItemsToList', items)
	},
	removeFromList({ commit }, id) {
		commit('removeItemFromList', id)
	}
}

// getters are functions.
const getters = {
	total: state => state.list.length
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
