import { mutationType, tabType } from './config'

import _ from 'lodash'
import { getDashboardListByOrderIds } from './ajax'
import logger from '../../../lib/utils/log'

export const initialFilter = {
	ids: null
}

export const mutations = {
	[mutationType.setOrderIdsFilter](state, filter) {
		if (state.tabFilter.tab === tabType.orderIds) {
			_.set(state, 'tabFilter.filter', {
				...state.tabFilter.filter,
				...filter
			})
			return
		}
		logger.info(
			`[Backoffice][Dashboard] Mismatch tab type, expected: ${tabType.orderIds}`
		)
	}
}

export const actions = {
	updateOrderIdsFilter({ commit }, filter = {}) {
		commit(mutationType.setOrderIdsFilter, filter)
	},
	resetOrderIdsFilter({ commit }) {
		commit(mutationType.setOrderIdsFilter, initialFilter)
	},
	async getDashboardByOrderIdsFilter(
		{ commit, dispatch, state },
		filter = {}
	) {
		const currentFilter = state.tabFilter.filter
		const idsFilter = filter.ids || currentFilter.ids
		// clear non-digit char and split '\n' and ','
		const orderIdList =
			idsFilter &&
			_.flow([
				() => _.replace(idsFilter, /\n/g, ','),
				(...args) => _.replace(args, /((?!,)\D)+|\s+/g, ''),
				(...args) => _.split(args, ','),
				args => _.without(args, '')
			])()
		const orderIdListFilter = {
			...currentFilter,
			ids: orderIdList && _.join(orderIdList, ',')
		}
		if (!orderIdListFilter.ids) {
			// return function directly if no ids
			await dispatch('updateOrderIdsFilter', orderIdListFilter)
			commit(mutationType.setDashboard, {
				data: [],
				total: 0
			})
			return
		}
		const data = await getDashboardListByOrderIds(orderIdListFilter.ids)
		await dispatch('updateOrderIdsFilter', orderIdListFilter)
		await dispatch('updateSelectedOrders', [])
		commit(mutationType.setDashboard, { data, total: data.length })
	}
}
