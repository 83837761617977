import axiosApiClient from './axiosApiClient'

/**
 * @typedef {import('./orders-api.js').Order} Order
 * @typedef {import('./users-api.js').User} User
 * @typedef {Pick<User, 'id' | 'info' | 'tags' | 'aliases'>} Courier
 * @typedef {{
	id: number,
	clientId: string,
	courier: Courier,
	deliveryBy: string,
	pickupAt: string,
	hasSla: boolean,
	isFlagged: boolean,
	meta: Record<string, any>[],
	order?: Order,
	status: string,
	createdAt: Date,
	updatedAt: Date,
	orderRef?: string,
	parcelRef: string,
	orderId?: number,
	clientRefs: Record<string, string>[],
	formSubmissions: formSubmission[]
	}} Delivery
  @typedef {{
	id: number,
	clientId: string[],
	locationId: string,
	tag: string,
	fields: Record<string, string>[],
	createdAt: Date,
	updatedAt: Date,
	submittedAt: Date,
	deletedAt?: Date,
	formId: string,
	meta: Record<string, any>[],
	isFlag: boolean,
	userId: number,
	user: Courier,
	submitterRef: string,
	clientRefs: Record<string, string>[]
}} formSubmission */

/**
 * @type {(params: {
  clientId?: string,
	clientRefType?: string,
	clientRef?: string,
	courierId?: number,
	min_deliveryBy?: Date,
	max_deliveryBy?: Date,
	isFlagged?: boolean
	}) => Promise<Delivery>
} */
export const getDeliveries = async (params = {}) => {
	/** @type {import('axios').AxiosResponse<Delivery>} */
	const response = await axiosApiClient.get(`/deliveries`, { params })
	return response.data
}
