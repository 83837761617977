export const DELIVERY_STATUS = {
	// pending states
	AWAITS_PICKUP: 'AWAITS_PICKUP',
	AWAITS_DRIVER: 'AWAITS_DRIVER',
	FAILED_PICKUP: 'FAILED_PICKUP',

	// non-end states
	FAILED: 'FAILED',
	DELIVERING: 'DELIVERING',
	RESCHEDULED: 'RESCHEDULED',

	// end states
	RETURNED: 'RETURNED',
	CANCELED: 'CANCELED',
	DELIVERED: 'DELIVERED',

	IN_TRANSIT: 'IN_TRANSIT'
}

export const ORDER_STATUS = {
	IN_PROGRESS: 'IN_PROGRESS',
	PARTIAL_DELIVERED: 'PARTIAL_DELIVERED',
	DELIVERED: 'DELIVERED',
	COMPLETED: 'COMPLETED',
	CANCELED: 'CANCELED',
	CLOSED: 'CLOSED',
	NOT_DONE: 'NOT_DONE',
	DONE: 'DONE'
}

export const ORDER_STATUS_GROUP = {
	END: [
		ORDER_STATUS.CLOSED,
		ORDER_STATUS.CANCELED,
		ORDER_STATUS.COMPLETED,
		ORDER_STATUS.DELIVERED
	]
}

export const DELIVERY_STATUS_GROUP = {
	PENDING: [
		DELIVERY_STATUS.AWAITS_PICKUP,
		DELIVERY_STATUS.AWAITS_DRIVER,
		DELIVERY_STATUS.FAILED_PICKUP
	],
	INTERIM: [
		DELIVERY_STATUS.DELIVERING,
		DELIVERY_STATUS.FAILED,
		DELIVERY_STATUS.RESCHEDULED
	],
	END: [
		DELIVERY_STATUS.RETURNED,
		DELIVERY_STATUS.CANCELED,
		DELIVERY_STATUS.DELIVERED
	],
	IN_TRANSIT: [DELIVERY_STATUS.IN_TRANSIT]
}
