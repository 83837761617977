/* global _ */
import axiosApiClient from './axiosApiClient'
import logger from '../lib/utils/log'

/**
 * @typedef {{
	id: number
	info: Record<string, any>
	aliases: Record<string, any>[]
	roles: Record<string, any>[]
	tags Record<string, any>[]
	isVerified: boolean
	clients: Record<string, any>[]
	courier: Record<string, any>
}} User
 */

/** @type {(options: {limit: number, page: number, ...any}) => Promise<User[]>} */
export const getUsers = async options => {
	/** @type {import('axios').AxiosResponse<User[]>} */
	const response = await axiosApiClient({
		url: '/v1/users',
		params: {
			...(_.omitBy(options, _.isNull) || null)
		}
	})
	return response.data
}

/** @type {(userId: number) => Promise<User | null>} */
export const getUser = async userId => {
	try {
		/** @type {import('axios').AxiosResponse<User | null>} */
		const response = await axiosApiClient.get(`/users/${userId}`)
		return response.data
	} catch (e) {
		logger.error(
			`Error on GET single user ${userId}`,
			e.response?.data?.errors || e.stack
		)
		return {}
	}
}

/** @type {(users: User[]) => Promise<string>} */
export const createUsers = async users => {
	/** @type {import('axios').AxiosResponse<null>} */
	const response = await axiosApiClient({
		method: 'POST',
		url: '/users',
		data: users
	})
	return response.status && response.status === 200
}

/** @type {(user: User) => Promise<string>} */
export const updateUser = async ({ id: userId, ...userWithoutId }) => {
	/** @type {import('axios').AxiosResponse<null>} */
	const response = await axiosApiClient({
		method: 'PATCH',
		url: `/users/${userId}`,
		data: userWithoutId
	})
	return response.status && response.status === 200
}

/** @type {(userId: number) => Promise<string>} */
export const deleteUser = async userId => {
	/** @type {import('axios').AxiosResponse<User | null>} */
	const response = await axiosApiClient.delete(`/users/${userId}`)
	return response.data
}
