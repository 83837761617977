import _ from 'lodash'
import axiosApiClient from './axiosApiClient'

/**
 * @typedef {{
	id: number,
	type: string,
	createdAt: Date,
	timezone: string,
	payload: Record<string, any>
}} OrderEvent */

/** @type {(id) => Promise<OrderEvent[]>} */
export const getOrderEvents = async orderEventId => {
	/** @type {import('axios').AxiosResponse<OrderEvent[]>} */
	const response = await axiosApiClient.get(`/order-events/${orderEventId}`)
	return _.get(response, 'data')
}
