import _ from 'lodash'
import axiosApiClient from '../../api/axiosApiClient'
import logger from '../../lib/utils/log'

const smsMutation = {
	setSMS: 'SET_SMS',
	setFilter: 'SET_FILTER',
	setClientList: 'SET_CLIENT_LIST'
}

const defaultFilter = {
	deliveryId: null,
	clientId: null,
	success: null,
	page: 1,
	limit: 100,
	sortBy: 'createdAt',
	sortDirection: 'DESC'
}

const state = {
	data: [],
	total: 0,
	totalPage: 1,
	clientList: [],
	filter: defaultFilter
}

const mutations = {
	[smsMutation.setSMS]: function (
		state,
		{ data = [], total = 0, totalPage = 1 }
	) {
		_.set(state, 'data', data)
		_.set(state, 'total', total)
		_.set(state, 'totalPage', totalPage)
	},
	[smsMutation.setFilter]: function (state, filter = {}) {
		_.set(state, 'filter', { ...state.filter, ...filter })
	},
	[smsMutation.setClientList]: function (state, clientList = []) {
		_.set(state, 'clientList', clientList)
	}
}

const actions = {
	async getSMS({ state, commit, dispatch }, filter = {}) {
		//	Build params with the state.filter(old state) and filter(new input)
		const filteringPagination = filter.page
			? filter
			: { ...defaultFilter, ...filter }
		const params = { ...state.filter, ...filteringPagination }

		const { data, total, page, limit } = await getSMSList(params)
		const totalPage = total <= 100 ? 1 : _.ceil(total / state.limit)

		await commit(smsMutation.setSMS, { data, total, totalPage })
		await dispatch('updateFilter', { ...params, page, limit })
	},
	async getClientList({ state, commit }) {
		if (state.clientList.length === 0) {
			commit(smsMutation.setClientList, await getClientList())
		}
	},
	updateFilter({ commit }, filter) {
		commit(smsMutation.setFilter, filter)
	},
	resetFilter({ commit }) {
		commit(smsMutation.setFilter, defaultFilter)
	}
}

const getters = {}

const namespaced = true

async function getSMSList(params = {}) {
	try {
		return _.get(await axiosApiClient('/sms', { params }), 'data', {})
	} catch (e) {
		logger.error('[Backoffice][SMS] Failed to get sms list', e, e.stack)
		return {}
	}
}

async function getClientList() {
	try {
		return _.get(await axiosApiClient('/clients'), 'data')
	} catch (e) {
		logger.error('[Backoffice][SMS] Failed to get client list', e, e.stack)
		return []
	}
}

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced
}
