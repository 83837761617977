import axiosApiClient from './axiosApiClient'
import _ from 'lodash'

/**
 * @typedef {{
	id: number
	displayId: string
	shiftId: number
	user: Record<string, any>
	status: 'APPROVED' | 'PENDING' | 'REJECTED'
	districts: string[]
	createdAt: string
	updatedAt: string
	shift: Record<string, any>
}} ShiftRegistration
 */

/** @typedef {{ limit: number, page: number, total: number, statusCount: Record<string, number>, data: ShiftRegistration[] }} ShiftRegistrationPaginatedResponse */

/** @type {(options: {limit: number, page: number, date: string, locationId: string, shiftStartAt: string, shiftEndAt: string, status: 'APPROVED' | 'PENDING' | 'REJECTED', shiftId: string}) => Promise<ShiftRegistrationPaginatedResponse>} */

export const getShiftRegistrations = async options => {
	const params = {
		...(_.omitBy(options, _.isNull) || null)
	}
	const response = await axiosApiClient({
		method: 'GET',
		url: 'backoffice/v1/shift-registrations',
		params
	})
	return response.data
}

/**
 * @typedef {{
	id: number
	displayId: string
	shiftId: number
	userId: number
	status: 'APPROVED' | 'PENDING' | 'REJECTED'
	districts: string[]
	createdAt: string
	updatedAt: string
}} ShiftRegistrations
 */

/** @type {(body: {shiftRegistrationId: number[],status: 'APPROVED' | 'PENDING' | 'REJECTED', shiftId: string}) => Promise<ShiftRegistrations>} */

export const approveShiftRegistrations = async body => {
	const response = await axiosApiClient({
		method: 'POST',
		url: 'backoffice/v1/approve-shift-registrations',
		data: { ...(_.omitBy(body, _.isNull) || null) }
	})
	return response.data
}
